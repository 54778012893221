import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FirebaseDatabaseService from '../services/database/strategies/FirebaseFirestoreService';
import { Chat, Message } from '../types/collections/Chats';

const CreateLesson: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [messageId] = useState(searchParams.get('message_id'));
  const [lessonCreated, setLessonCreated] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const createLesson = async () => {
      if (messageId && !isLoading) {
        setIsLoading(true);
        try {
          await FirebaseDatabaseService.getDocument<Message>(
            'messages',
            messageId,
            async (messageDoc) => {
              if (!messageDoc) {
                throw new Error('Message not found');
              }

              if (messageDoc.lessonCreated) {
                setError('A lesson has already been created for this message.');
                console.log('lesson created');
                setIsLoading(false);
                return;
              }

              if (messageDoc.lessonRequest) {
                const { chatId } = messageDoc;
                await FirebaseDatabaseService.getDocument<Chat>(
                  'chat',
                  chatId,
                  async (doc) => {
                    if (!doc) {
                      throw new Error('Chat not found');
                    }
                    const lessonData = {
                      subject: messageDoc.lessonRequest!.subject || '',
                      level: messageDoc.lessonRequest!.level || '',
                      startDate: messageDoc.lessonRequest!.startDate || null,
                      time: messageDoc.lessonRequest!.time || '',
                      numberOfLessons: messageDoc.lessonRequest!.numberOfLessons || 0,
                      cost: messageDoc.lessonRequest!.cost || 0,
                      status: 'scheduled',
                      studentId: messageDoc.studentId || '',
                      tutorId: messageDoc.tutorId || '',
                      paidAmount: messageDoc.paidAmount || 0,
                      paidAt: messageDoc.paidAt || null,
                      paymentIntentId: messageDoc.paymentIntentId || '',
                      participant1Id: doc.participant1Id || '',
                      participant2Id: doc.participant2Id || '',
                      participants: [doc.participant1Id, doc.participant2Id].filter(Boolean),
                    };

                    console.log('Retrieved chat');

                    // Create a new lesson in Firestore
                    FirebaseDatabaseService.addDocument(
                      'lessons',
                      lessonData,
                      () => {
                        console.log('created');
                        FirebaseDatabaseService.updateDocument<Message>(
                          'messages',
                          messageId,
                          { lessonCreated: true },
                          () => {
                            setLessonCreated(true);
                            console.log('here');
                            setIsLoading(false);
                            navigate('/');
                          },
                          (updateError) => {
                            setError('Lesson created but failed to update message. Please contact support.');
                            console.error('Failed to update message:', updateError.message);
                            setIsLoading(false);
                          },
                        );
                      },
                      (createError) => {
                        console.log('error', createError.message);
                        setError('Failed to create lesson. Please try again.');
                        setIsLoading(false);
                      },
                    );
                  },
                  (chatError) => {
                    setError('Failed to fetch chat information. Please try again.');
                    console.error('error:', chatError.message);
                    setIsLoading(false);
                  },
                );
              } else {
                setError('No lesson request found in the message.');
                setIsLoading(false);
              }
            },
            (messageError) => {
              setError('Failed to fetch message information. Please try again.');
              console.error('Failed to fetch message:', messageError.message);
              setIsLoading(false);
            },
          );
        } catch (err) {
          setError('An unexpected error occurred. Please try again or contact support.');
          console.error('Unexpected error:', err);
          setIsLoading(false);
        }
      }
    };

    createLesson();
  }, [messageId, navigate, isLoading]);

  return (
    <div className="text-center bg-sky-500 dark:bg-sky-900 flex items-center justify-center w-screen h-screen flex-col gap-2">
      <div className="flex justify-center items-center rounded-full border border-white">
        <div className="animate-spin rounded-full h-12 w-12 border-b-4 border-blue-700" />
      </div>
      {error && <h2 className="text-2xl text-red-600 dark:text-red-400 font-bold">{error}</h2>}
      {lessonCreated ? (
        <h2 className="text-2xl text-gray-900 dark:text-white font-bold">Lesson created successfully!</h2>
      ) : (
        <h2 className="text-2xl text-gray-900 dark:text-white font-bold">
          {isLoading ? 'Creating your lesson...' : 'Preparing to create lesson...'}
        </h2>
      )}
    </div>
  );
};

export default CreateLesson;
