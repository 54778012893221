import React from 'react';
import Jdenticon from 'react-jdenticon';
import { Chat, Message } from '../../../../types/collections/Chats';
import { useAuth } from '../../../../contexts/AuthContext';

interface ChatBubbleProps {
  chat: Chat;
  message: Message;
}

const ChatBubble: React.FC<ChatBubbleProps> = ({ chat, message }) => {
  const { authState } = useAuth();
  const renderMessageContent = () => (
    <>
      <p className="text-sm font-normal py-2.5 text-gray-900 dark:text-white">
        {message.text}
      </p>
      {message.file && (
      <div className="flex items-start my-2.5 bg-gray-50 dark:bg-gray-600 rounded-xl p-2">
        <div className="me-2 px-2">
          <span className="flex items-center gap-2 text-sm font-medium text-gray-900 dark:text-white pb-2">
            {message.file.fileName}
          </span>
          <span className="flex text-xs font-normal text-gray-500 dark:text-gray-400 gap-2">
            {message.file.fileSize}
            {' '}
            •
            {message.file.fileType}
          </span>
        </div>
        <div className="inline-flex self-center items-center">
          <button onClick={() => window.open(message.file?.fileUrl, '_blank')} className="inline-flex self-center items-center p-2 text-sm font-medium text-center text-gray-900 bg-gray-50 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-600 dark:hover:bg-gray-500 dark:focus:ring-gray-600" type="button">
            <svg className="w-4 h-4 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z" />
              <path d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
            </svg>
          </button>
        </div>
      </div>
      )}
      {message.image && (
      <div className="group relative my-2.5">
        <div className="absolute w-full h-full bg-gray-900/50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg flex items-center justify-center">
          <button onClick={() => window.open(message.image?.imageUrl, '_blank')} className="inline-flex items-center justify-center rounded-full h-10 w-10 bg-white/30 hover:bg-white/50 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50" type="button">
            <svg className="w-5 h-5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 18">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3" />
            </svg>
          </button>
        </div>
        <img src={message.image.imageUrl} className="rounded-lg" alt={message.image.caption || 'Image'} />
        {message.image.caption && (
        <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">{message.image.caption}</p>
        )}
      </div>
      )}
    </>
  );

  if (authState.user && message.sender === authState.user.uid) {
    return (
      <div className="w-full flex justify-end items-end">
        <div className="flex items-start gap-2.5 my-2">
          <div className="flex flex-col w-full max-w-[320px] leading-1.5 p-4 border-gray-200 bg-blue-100 rounded-l-xl rounded-t-xl dark:bg-gray-700">
            <div className="flex items-center space-x-2 rtl:space-x-reverse">
              <span className="text-sm font-semibold text-gray-900 dark:text-white">{message.sender === chat.participant1Id ? chat.participant1Name : chat.participant2Name}</span>
              <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                {new Date(message.timestamp).toLocaleString()}
              </span>
            </div>
            {renderMessageContent()}
            <span className="text-sm font-normal text-gray-500 dark:text-gray-400">{message.status}</span>
          </div>
          <Jdenticon size="50" value={message.sender === chat.participant1Id ? chat.participant1Name : chat.participant2Name} />
          {/* Dropdown menu button */}
        </div>
      </div>
    );
  }
  return (
    <div className="flex items-start gap-2.5 my-2">
      <Jdenticon size="50" value={message.sender === chat.participant1Id ? chat.participant1Name : chat.participant2Name} />
      <div className="flex flex-col w-full max-w-[320px] leading-1.5 p-4 border-gray-200 bg-gray-100 rounded-e-xl rounded-es-xl dark:bg-gray-700">
        <div className="flex items-center space-x-2 rtl:space-x-reverse">
          <span className="text-sm font-semibold text-gray-900 dark:text-white">{message.sender === chat.participant1Id ? chat.participant1Name : chat.participant2Name}</span>
          <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
            {new Date(message.timestamp).toLocaleString()}
          </span>
        </div>
        {renderMessageContent()}
        <span className="text-sm font-normal text-gray-500 dark:text-gray-400">{message.status}</span>
      </div>
      {/* Dropdown menu button */}
    </div>
  );
};

export default ChatBubble;
