import React from 'react';

export interface ScheduledEvent {
  date: Date;
  component: React.ReactNode;
}

export interface Day {
  date: Date;
  isSelected: boolean;
}

export interface Month {
  name: string;
  days: Day[];
}

export const getMonthDetails = (date: Date): Month => {
  const year = date.getFullYear();
  const month = date.getMonth(); // 0-indexed
  const startDay = new Date(year, month, 1);
  const endDay = new Date(year, month + 1, 0);
  const days: Day[] = [];

  for (let d = new Date(startDay); d <= endDay; d.setDate(d.getDate() + 1)) {
    days.push({
      date: new Date(d),
      isSelected: false, // Default all days as not selected
    });
  }

  return {
    name: startDay.toLocaleString('default', { month: 'long', year: 'numeric' }),
    days,
  };
};
