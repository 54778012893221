import React from 'react';

export interface BreadcrumbStepperProps {
  stepCompletedIndex: number;
  stepNames: string[];
}

export const BreadcrumbStepper: React.FC<BreadcrumbStepperProps> = ({
  stepCompletedIndex,
  stepNames,
}) => (
  <ol className="flex items-center justify-start md:justify-evenly flex-wrap w-full p-3 space-x-2 text-sm font-medium text-center text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 sm:text-base dark:bg-gray-800 dark:border-gray-700 sm:p-4 sm:space-x-4 rtl:space-x-reverse">
    {
      stepNames.map((stepName, index) => {
        if (index <= stepCompletedIndex) {
          return (
            <li className="flex items-center text-blue-600 dark:text-blue-500">
              <span className="flex items-center justify-center w-5 h-5 me-2 text-xs border border-blue-600 rounded-full shrink-0 dark:border-blue-500">
                {index + 1}
              </span>
              {stepName}
              {' '}
              <svg className="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m7 9 4-4-4-4M1 9l4-4-4-4" />
              </svg>
            </li>
          );
        } if (index === stepNames.length - 1) {
          return (
            <li className="flex items-center">
              <span className="flex items-center justify-center w-5 h-5 me-2 text-xs border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
                {index + 1}
              </span>
              {stepName}
              {' '}
            </li>
          );
        }
        return (
          <li className="flex items-center">
            <span className="flex items-center justify-center w-5 h-5 me-2 text-xs border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
              {index + 1}
            </span>
            {stepName}
            {' '}
            <svg className="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m7 9 4-4-4-4M1 9l4-4-4-4" />
            </svg>
          </li>
        );
      })
    }
  </ol>
);
