export type Subjects = 'STEP' | 'English' | 'Mathematics' | 'Computer Science' | 'Machine Learning' | 'Physics' | 'Chemistry'

export type Subject = {
  id: string;
  label: Subjects;
  colors: {
    from: string;
    to: string;
  };
};

export const SubjectMapping: Subject[] = [
  { id: 'step', label: 'STEP', colors: { from: '#8e24aa', to: '#2196f3' } },
  { id: 'science', label: 'English', colors: { from: '#00bcd4', to: '#2196f3' } },
  { id: 'mathematics', label: 'Mathematics', colors: { from: '#66bb6a', to: '#1e88e5' } },
  { id: 'computer-science', label: 'Computer Science', colors: { from: '#4db6ac', to: '#dce775' } },
  { id: 'machine-learning', label: 'Machine Learning', colors: { from: '#ef9a9a', to: '#e57373' } },
  { id: 'physics', label: 'Physics', colors: { from: '#9c27b0', to: '#e91e63' } },
  { id: 'chemistry', label: 'Chemistry', colors: { from: '#e91e63', to: '#ff7043' } },
];
