import React, {
  createContext, useContext, ReactNode, useState, useEffect, useMemo,
} from 'react';
import { User } from '../types/User';
import FirebaseAuthService from '../services/authentication/strategies';

interface AuthState {
  isAuthenticated?: boolean;
  user: null | User;
}

interface AuthContextProps {
  authState: AuthState;
  login: (
    email: string,
    password: string,
    onSuccess?: () => void,
    onFailure?: (error: string) => void
  ) => void;
  logout: (onSuccess?: () => void, onFailure?: (error: string) => void) => void;
  register: (
    email: string,
    name: string,
    password: string,
    onSuccess?: () => void,
    onFailure?: (error: string) => void
  ) => void;
  sendVerificationEmail: (
    onSuccess?: () => void,
    onFailure?: (error: string) => void
  ) => void;
  checkEmailVerification: (
    onSuccess?: (isVerified: boolean) => void,
    onFailure?: (error: string) => void
  ) => void;
  sendPasswordResetEmail: (
    email: string,
    onSuccess?: () => void,
    onFailure?: (error: string) => void
  ) => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

// Provider component
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [authState, setAuthState] = useState<AuthState>({ user: null });

  useEffect(() => {
    const unsubscribe = FirebaseAuthService.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        setAuthState({
          isAuthenticated: true,
          user: {
            uid: firebaseUser.uid,
            name: firebaseUser.name || '',
            emailVerified: firebaseUser.emailVerified,
            email: firebaseUser.email || '',
          },
        });
      } else {
        setAuthState({ isAuthenticated: false, user: null });
      }
    });

    return () => unsubscribe();
  }, []);

  const login = async (
    email: string,
    password: string,
    onSuccess?: () => void,
    onFailure?: (error: string) => void,
  ) => {
    try {
      await FirebaseAuthService.login(email, password);
      if (onSuccess) onSuccess();
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
      if (onFailure) onFailure(errorMessage);
    }
  };

  const register = async (
    email: string,
    name: string,
    password: string,
    onSuccess?: () => void,
    onFailure?: (error: string) => void,
  ) => {
    try {
      await FirebaseAuthService.register(email, name, password);
      if (onSuccess) onSuccess();
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
      if (onFailure) onFailure(errorMessage);
    }
  };

  const logout = async (onSuccess?: () => void, onFailure?: (error: string) => void) => {
    try {
      await FirebaseAuthService.logout();
      if (onSuccess) onSuccess();
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
      if (onFailure) onFailure(errorMessage);
    }
  };

  const sendVerificationEmail = async (
    onSuccess?: () => void,
    onFailure?: (error: string) => void,
  ) => {
    try {
      await FirebaseAuthService.sendVerificationEmail();
      if (onSuccess) onSuccess();
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
      if (onFailure) onFailure(errorMessage);
    }
  };

  const checkEmailVerification = async (
    onSuccess?: (isVerified: boolean) => void,
    onFailure?: (error: string) => void,
  ) => {
    try {
      const isVerified = await FirebaseAuthService.checkEmailVerification();
      if (onSuccess) onSuccess(isVerified);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
      if (onFailure) onFailure(errorMessage);
    }
  };

  const sendPasswordResetEmail = async (
    email: string,
    onSuccess?: () => void,
    onFailure?: (error: string) => void,
  ) => {
    try {
      await FirebaseAuthService.sendPasswordResetEmail(email);
      if (onSuccess) onSuccess();
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
      if (onFailure) onFailure(errorMessage);
    }
  };

  const value = useMemo(() => ({
    authState,
    login,
    logout,
    register,
    sendVerificationEmail,
    checkEmailVerification,
    sendPasswordResetEmail,
  }), [authState]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
