import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import './styles/tailwind.css';
import Landing from './pages/Landing';
import About from './pages/About';
import { AuthProvider } from './contexts/AuthContext';
import Authentication from './pages/Authentication';
import { Settings } from './pages/Settings';
import { DarkModeProvider } from './contexts/DarkModeContext';
import { NewLesson } from './pages/NewLesson';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { NotFoundPage } from './pages/NotFoundPage';
import { Messages } from './pages/Messages';
import PaymentSuccessfulHandler from './pages/PaymentSuccessfulHandler';
import CreateLessonPage from './pages/CreateLessonPage';
import ContactUs from './pages/ContactUs';

const App: React.FC = () => (
  <AuthProvider>
    <DarkModeProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/about" element={<About />} />
          <Route path="/new-lesson" element={<NewLesson />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/contact" element={<ContactUs />} />

          <Route path="/create-lesson" element={<CreateLessonPage />} />
          <Route path="/payment-success" element={<PaymentSuccessfulHandler />} />

          {/* Student Specific Routes */}
          <Route path="/authenticate" element={<Authentication />} />

          {/* Tutor Specific Routes */}
          <Route path="/settings" element={<Settings />} />

          {/* Static Pages */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          <Route
            path="*"
            element={<NotFoundPage />}
          />
        </Routes>
      </Router>
    </DarkModeProvider>
  </AuthProvider>
);

export default App;
