import React, { useEffect, useState } from 'react';
import Jdenticon from 'react-jdenticon';
import { Chat } from '../../../types/collections/Chats';
import { useAuth } from '../../../contexts/AuthContext';
import FirebaseDatabaseService from '../../../services/database/strategies/FirebaseFirestoreService';

interface ChatListItemProps {
  chat: Chat;
  onSelectChat: (chat: Chat) => void;
  isSelected: boolean;
}

const ChatListItem: React.FC<ChatListItemProps> = ({ chat, onSelectChat, isSelected }) => {
  const { authState } = useAuth();
  const [updatedChat, setUpdatedChat] = useState(chat);

  useEffect(() => {
    const unsubscribe = FirebaseDatabaseService.listenToDocument<Chat>(
      'chat',
      chat.id,
      (updatedChatData) => {
        if (updatedChatData) {
          setUpdatedChat((prevState) => ({ ...prevState, ...updatedChatData }));
        }
      },
      (error) => {
        console.error('Error listening to chat updates:', error);
      },
    );

    return () => unsubscribe();
  }, [chat.id]);

  const otherParticipantName = authState.user && updatedChat.participant1Id === authState.user.uid
    ? updatedChat.participant2Name
    : updatedChat.participant1Name;

  return (
    <button
      onClick={() => onSelectChat(updatedChat)}
      className={`w-full flex items-center p-2 rounded-lg ${isSelected ? 'bg-blue-200 dark:bg-primary' : 'hover:bg-gray-100 dark:hover:bg-gray-700'}`}
      type="button"
    >
      <div className="w-12 h-12 rounded-full flex justify-center items-center overflow-hidden mr-3">
        <Jdenticon size="50" value={otherParticipantName} />
      </div>
      <div className="flex-grow text-left">
        <h3 className="font-semibold">{otherParticipantName}</h3>
        <p className="text-sm text-gray-500 dark:text-white truncate">{updatedChat.lastMessage}</p>
      </div>
      <div className="text-xs text-gray-400 dark:text-white">
        {new Date(updatedChat.lastMessageTime).toLocaleString()}
      </div>
    </button>
  );
};

export default ChatListItem;
