import React from 'react';

/*

    Engaged Layout View:

        This is the layout view without a navigation bar, it's important for steps like
        authentication where the user's attention should be focused on the body of the work.

        Example routes:
            - /register-student

*/

interface EngagedLayoutProps {
    children: React.ReactNode;
}

const EngagedLayout: React.FC<EngagedLayoutProps> = ({ children }) => (
  <div className="flex flex-col min-h-screen min-w-screen items-center justify-center dark:bg-gray-900">
    {children}
  </div>
);

export default EngagedLayout;
