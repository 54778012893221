import React, { useState } from 'react';
import FirebaseStorageService from '../../../../../services/storage/strategies/FirebaseStorageService';
import { TutorDocument } from '../../../../../types/collections/Tutor';

export interface IDConfirmationProps {
  idConfirmed: boolean;
  userId: string;
  tutorDoc: TutorDocument,
  createOrUpdateTutorDocument: (values: Partial<TutorDocument>) => void
}

export const IDConfirmation:React.FC<IDConfirmationProps> = ({
  idConfirmed, userId, tutorDoc, createOrUpdateTutorDocument,
}) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File>();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const uploadFile = async () => {
    setLoading(true);
    if (file) {
      try {
        const filePath = `users/${userId}/identification/${file.name}`;
        const downloadedUrl = await FirebaseStorageService.uploadFile(
          filePath,
          file,
          (currProgress) => setProgress(currProgress),
        );
        createOrUpdateTutorDocument({
          identificationUploadedLocation: downloadedUrl,
          identificationUploadedDate: new Date(),
        });
        setLoading(false);
      } catch (uploadErr) {
        setError(`Error uploading file: ${uploadErr}`);
        setLoading(false);
      }
    }
  };

  return (
    <div id="accordion-flush-body-2" hidden={idConfirmed} aria-labelledby="accordion-flush-heading-2">
      {error && (
      <div
        className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
        role="alert"
      >
        <svg
          className="flex-shrink-0 inline w-4 h-4 me-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
          />
        </svg>
        <span className="sr-only">Info</span>
        <div>
          <span className="font-medium">Error: </span>
          {' '}
          {error}
        </div>
      </div>
      )}
      <div className="py-5 border-b border-gray-200 dark:border-gray-700">
        <p className="mb-2 text-gray-500 dark:text-gray-400">Before you can begin being a tutor we need a form of identification either a passport or driving licence</p>
        <p className="text-gray-500 dark:text-gray-400">
          Please include both pages of your passport photo in a single upload.
        </p>
        <label className="block my-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="identificationFile">
          Upload Passport / Driving License
          <input
            aria-describedby="user_avatar_help"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 file:border-0 file:bg-gray-900 file:text-white file:p-2.5 file:rounded h-12 file:h-full"
            id="identificationFile"
            type="file"
            onChange={handleFileChange}
          />
        </label>
        <div className="flex gap-1">
          <button
            type="button"
            disabled={loading}
            className="text-white bg-blue-700 disabled:bg-blue-200 dark:disabled:bg-blue-200 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={() => {
              uploadFile().then((r) => {
                console.log(r);
              });
            }}
          >
            {loading ? 'Loading' : 'Upload ID' }
          </button>

          {tutorDoc.identificationUploadedLocation !== '' && (
          <button
            type="button"
            className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
            onClick={() => {
              window.location.href = tutorDoc.identificationUploadedLocation;
            }}
          >
            Open ID
          </button>
          )}
        </div>

        {progress > 0 && (
        <div className="relative h-2 rounded-full border border-gray-100">
          <div style={{ width: `${Math.round(progress)}%` }} className="absolute top-0 h-2 rounded-full border border-gray-200 bg-primary" />
        </div>
        )}
      </div>
    </div>
  );
};
