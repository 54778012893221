export interface TimeRange { startTime: string; endTime: string; }

export interface SubjectPricing {
  [level: string]: number; // e.g., "GCSE": 65
}

export interface TutorProfileType {
  id: string;
  activeStudents: number;
  description: string;
  experienceYears: number;
  biography: string;
  availability: {
    [day: string]: TimeRange;
  };
  pricing: {
    [subject: string]: SubjectPricing;
  };
  cvLink: string;
  confirmed: boolean;
}

export const DefaultLevels = [
  'Pre-GCSE (11-15)',
  'GCSE',
  'A-Levels',
  'Math Challenge',
  'Entrance Exams (Admissions tests)',
  'Undergrad',
  'Post-Grad',
];

export const DefaultPricing: SubjectPricing = {
  'Pre-GCSE (11-15)': 30,
  GCSE: 35,
  'A-Levels': 40,
  'Math Challenge': 45,
  'Entrance Exams (Admissions tests)': 50,
  Undergrad: 55,
  'Post-Grad': 60,
};

export const DefaultAvailability: { [key: string]: TimeRange } = {
  monday: { startTime: '09:00', endTime: '17:00' },
  tuesday: { startTime: '09:00', endTime: '17:00' },
  wednesday: { startTime: '09:00', endTime: '17:00' },
  thursday: { startTime: '09:00', endTime: '17:00' },
  friday: { startTime: '09:00', endTime: '17:00' },
  saturday: { startTime: '10:00', endTime: '15:00' },
  sunday: { startTime: '10:00', endTime: '15:00' },
};

export const ExampleTutorProfileCard: TutorProfileType = {
  id: 'something',
  activeStudents: 5,
  experienceYears: 3,
  description: 'Hello! I\'m John Smith. I can help you excel at STEM using active recall!',
  biography: '## John Smith - Expert Mathematics Tutor...', // (biography content truncated for brevity)
  availability: DefaultAvailability,
  pricing: {
    Mathematics: {
      GCSE: 65,
      'A-Levels': 70,
    },
  },
  cvLink: 'NA',
  confirmed: false,
};
