import React, { useState } from 'react';
import Jdenticon from 'react-jdenticon';
import { UserDocument } from '../../../../types/collections/User';
import { MarketingBanner } from '../../../banners/MarkettingBanner';

export interface UserInformationProps {
    userDocument: UserDocument,
    setUserDocument: React.Dispatch<React.SetStateAction<UserDocument>>,
}

export const UserInformation: React.FC<UserInformationProps> = (
  { userDocument, setUserDocument },
) => {
  const { accountType } = userDocument;
  const [errors, setErrors] = useState({
    fullName: '',
    addressFirstLine: '',
    addressSecondLine: '',
    phoneNumber: '',
    city: '',
    postCode: '',
    email: '',
    gcseYear: '',
  });

  const validateForm = (newDocument: UserDocument) => {
    const valid = true;
    const errorForm = {
      fullName: '',
      addressFirstLine: '',
      addressSecondLine: '',
      phoneNumber: '',
      city: '',
      postCode: '',
      email: '',
      gcseYear: '',
    };

    if (newDocument.fullName === '') {
      errorForm.fullName = 'Set a full name';
    }

    const pattern = /^\+?[0-9]{2}\s?0?[0-9]{5}\s?[0-9]{5}$/;
    if (newDocument.phoneNumber !== '' && !pattern.test(newDocument.phoneNumber)) {
      errorForm.phoneNumber = 'Phone number must match pattern: +44 075760 501000';
    }

    const emailPattern = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/;
    if (newDocument.email !== '' && !emailPattern.test(newDocument.email)) {
      errorForm.email = 'Not a valid email address';
    }

    if (newDocument.gcseYear && newDocument.gcseYear !== '' && (parseInt(newDocument.gcseYear, 10) <= 1960 || parseInt(newDocument.gcseYear, 10) >= 2060)) {
      errorForm.gcseYear = '... Be reasonable';
    }

    if (newDocument.addressFirstLine === '') {
      errorForm.addressFirstLine = 'First line cannot be empty';
    }

    if (newDocument.city === '') {
      errorForm.city = 'City must be entered';
    }

    if (newDocument.postcode === '') {
      errorForm.postCode = 'Postcode must be entered';
    }
    // Set errors state
    setErrors(errorForm);
    return valid;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const newDocument: UserDocument = { ...userDocument, [name]: value };
    if (validateForm(newDocument)) {
      setUserDocument((prevForm) => ({ ...prevForm, [name]: value }));
    }
  };

  return (
    <div className="mx-auto max-w-screen-sm text-center">

      <MarketingBanner text="If you are applying to be a tutor you may be prompted to add additional information like your address." />

      {/* User Information Forum */}
      <h2 className="mb-4 text-4xl font-extrabold leading-tight text-gray-900 dark:text-white">User Information</h2>
      <p className="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">Please provide some additional information for your accounts</p>

      <form className="text-left">
        <div className="mb-6">

          <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
            <Jdenticon size="48" value={userDocument.fullName} />
          </div>
        </div>

        <div className="mb-6">
          <label htmlFor="fullName" className="block text-sm font-medium text-gray-900 dark:text-white">
            Full Name
            <input
              type="text"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              id="fullName"
              name="fullName"
              required
              placeholder="Full Name"
              value={userDocument.fullName}
              onChange={handleInputChange}
            />
            {errors.fullName && <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.fullName}</p>}
          </label>
        </div>

        <div className="mb-6">
          <label htmlFor="phone-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Phone number:
            <div className="relative">
              <div className="absolute inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none">
                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 19 18">
                  <path d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z" />
                </svg>
              </div>
              <input
                type="text"
                id="phone-input"
                aria-describedby="helper-text-explanation"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                pattern="\+?[0-9]{2}\s?[0-9]{5}\s?[0-9]{6}"
                placeholder="+44 07576 050100"
                required
                name="phoneNumber"
                value={userDocument.phoneNumber}
                onChange={handleInputChange}
              />
            </div>
          </label>
          {errors.phoneNumber && <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.phoneNumber}</p>}
          <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">Select a phone number that matches the format.</p>
        </div>

        <div className="mb-6">
          <label htmlFor="helper-text" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Your email
            <input
              type="email"
              id="helper-text"
              aria-describedby="helper-text-explanation"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="name@tutr.ai"
              name="email"
              value={userDocument.email}
              onChange={handleInputChange}
            />
            {errors.email && <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.email}</p>}
            <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">
              We’ll never share your details. Read our
              <a href="/privacy-policy" className="font-medium text-blue-600 hover:underline dark:text-blue-500"> Privacy Policy</a>
              .
            </p>
          </label>
        </div>

        <div className="mb-6">
          <label htmlFor="helper-text" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            When will/did you take your GCSE&apos;s
            Select a Year
            <input
              type="number"
              placeholder="YYYY"
              min="1999"
              max="2020"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              name="gcseYear"
              value={userDocument.gcseYear}
              onChange={handleInputChange}
            />
            {errors.gcseYear && <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.gcseYear}</p>}
          </label>
        </div>

        {
          accountType === 'tutor' && (
          <div className="mb-6">
            <label htmlFor="fullName" className="block text-sm font-medium text-gray-900 dark:text-white">
              First line of your address
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                id="addressFirstLine"
                name="addressFirstLine"
                required
                placeholder="Address First Line"
                value={userDocument.addressFirstLine}
                onChange={handleInputChange}
              />
              {errors.addressFirstLine && <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.addressFirstLine}</p>}
            </label>
          </div>
          )
        }

        {
            accountType === 'tutor' && (
            <div className="mb-6">
              <label htmlFor="addressSecondLine" className="block text-sm font-medium text-gray-900 dark:text-white">
                Second line of your address
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  id="addressSecondLine"
                  name="addressSecondLine"
                  required
                  placeholder="Second line of your address"
                  value={userDocument.addressSecondLine}
                  onChange={handleInputChange}
                />
                {errors.addressSecondLine && <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.addressSecondLine}</p>}
              </label>
            </div>
            )
        }

        {
          accountType === 'tutor' && (
          <div className="grid gap-6 mb-6 md:grid-cols-2">
            <div>
              <label htmlFor="helper-text" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                City
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="London"
                  name="city"
                  value={userDocument.city}
                  onChange={handleInputChange}
                />
                {errors.city && <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.city}</p>}
              </label>
            </div>
            <div>
              <label htmlFor="helper-text" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Postcode
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="SW67PS"
                  name="postcode"
                  value={userDocument.postcode}
                  onChange={handleInputChange}
                />
                {errors.postCode && <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.postCode}</p>}
              </label>
            </div>
          </div>
          )
        }
      </form>
    </div>
  );
};
