import React, { useState, useEffect } from 'react';
import { Chat, LessonRequest, Message } from '../../../../types/collections/Chats';
import FirebaseDatabaseService from '../../../../services/database/strategies/FirebaseFirestoreService';
import { TutorProfileType } from '../../../../types/TutorProfile';
import { Levels } from '../../../../types/Level';

export interface ScheduleLessonsProps {
  hideState: () => void;
  chat: Chat;
  tutorProfileType: TutorProfileType;
}

export const ScheduleLessons: React.FC<ScheduleLessonsProps> = ({
  hideState,
  chat,
  tutorProfileType,
}) => {
  console.log('here');

  const [lessonRequest, setLessonsRequest] = useState<LessonRequest>({
    tutorId: '',
    studentId: '',
    lessonDetails: '',
    startDate: '',
    time: '',
    level: 'GCSE' as Levels,
    recurrence: {
      frequency: 'once',
      daysOfWeek: [],
      interval: 1,
    },
    numberOfLessons: 1,
    subject: '',
    cost: 0,
  });

  const [availableSubjects, setAvailableSubjects] = useState<string[]>([]);
  const [availableLevels, setAvailableLevels] = useState<Levels[]>([]);

  useEffect(() => {
    setAvailableSubjects(Object.keys(tutorProfileType.pricing));

    if (Object.keys(tutorProfileType.pricing).length > 0) {
      const firstSubject = Object.keys(tutorProfileType.pricing)[0];
      const firstLevel = Object.keys(tutorProfileType.pricing[firstSubject])[0] as Levels;
      setLessonsRequest((prev) => ({
        ...prev,
        subject: firstSubject,
        level: firstLevel,
      }));
    }
  }, [tutorProfileType]);

  useEffect(() => {
    if (lessonRequest.subject) {
      setAvailableLevels(Object.keys(tutorProfileType.pricing[lessonRequest.subject]) as Levels[]);
    }
  }, [lessonRequest.subject, tutorProfileType.pricing]);

  useEffect(() => {
    if (lessonRequest.subject && lessonRequest.level) {
      const costPerLesson = tutorProfileType.pricing[lessonRequest.subject][lessonRequest.level];
      setLessonsRequest((prev) => ({
        ...prev,
        cost: costPerLesson * prev.numberOfLessons,
      }));
    }
  }, [
    lessonRequest.subject,
    lessonRequest.level,
    lessonRequest.numberOfLessons,
    tutorProfileType.pricing,
  ]);

  const handleChange = (e: React.ChangeEvent<
    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
  >) => {
    const { name, value } = e.target;
    setLessonsRequest((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleRecurrenceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setLessonsRequest((prev) => ({
      ...prev,
      recurrence: {
        ...prev.recurrence,
        frequency: value,
      },
    } as LessonRequest));
  };

  const handleDaysOfWeekChange = (day: string) => {
    setLessonsRequest((prev) => ({
      ...prev,
      recurrence: {
        ...prev.recurrence,
        daysOfWeek: prev.recurrence.daysOfWeek.includes(day)
          ? prev.recurrence.daysOfWeek.filter((d) => d !== day)
          : [...prev.recurrence.daysOfWeek, day],
      },
    }));
  };

  const bookLesson = () => {
    const message: Message = {
      id: '',
      sender: 'system',
      timestamp: Date.now(),
      systemType: 'lesson_schedule',
      lessonRequest,
      lessonStatus: 'pending',
      status: 'sent',
      chatId: chat.id,
    };

    FirebaseDatabaseService.addDocument(
      'messages',
      { ...message },
      (newMessageId) => {
        // Message sent successfully
        console.log('Message updated:', newMessageId);
        FirebaseDatabaseService.updateDocument(
          'chat',
          chat.id,
          {
            lastMessage: 'Lesson Proposed!',
            lastMessageTime: Date.now(),
          },
          () => {
            // Chat updated successfully
            hideState();
          },
        );
      },
      (error) => {
        // Handle error
        console.log(error.message);
      },
    );
  };

  return (
    <div className="absolute w-full h-full bg-black/20 flex items-center justify-center">
      <div className="relative p-4 w-full max-w-2xl max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 z-50">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              Schedule Lesson
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={hideState}
            >
              <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="p-4 md:p-5 space-y-4">
            <div>
              <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Start Date
                <input
                  type="date"
                  name="startDate"
                  id="startDate"
                  value={lessonRequest.startDate}
                  onChange={handleChange}
                  className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-600 dark:border-gray-500 dark:text-white"
                  min={new Date().toISOString().split('T')[0]}
                />
              </label>
            </div>
            <div>
              <label htmlFor="time" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Time
                <input
                  type="time"
                  name="time"
                  id="time"
                  value={lessonRequest.time}
                  onChange={handleChange}
                  className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-600 dark:border-gray-500 dark:text-white"
                />
              </label>
            </div>
            <div>
              <label htmlFor="recurrence" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Recurrence
                <select
                  name="recurrence"
                  id="recurrence"
                  value={lessonRequest.recurrence.frequency}
                  onChange={handleRecurrenceChange}
                  className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-600 dark:border-gray-500 dark:text-white"
                >
                  <option value="once">One-time</option>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                </select>
              </label>
            </div>
            {lessonRequest.recurrence.frequency === 'weekly' && (
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Days of Week</label>
                <div className="flex flex-wrap gap-2 mt-1">
                  {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day) => (
                    <button
                      type="button"
                      key={day}
                      onClick={() => handleDaysOfWeekChange(day)}
                      className={`px-2 py-1 text-sm rounded ${
                        lessonRequest.recurrence.daysOfWeek.includes(day)
                          ? 'bg-blue-500 text-white'
                          : 'bg-gray-200 text-gray-700'
                      }`}
                    >
                      {day}
                    </button>
                  ))}
                </div>
              </div>
            )}
            <div>
              <label htmlFor="numberOfLessons" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Number of Lessons
                <input
                  type="number"
                  name="numberOfLessons"
                  id="numberOfLessons"
                  value={lessonRequest.numberOfLessons}
                  onChange={handleChange}
                  className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-600 dark:border-gray-500 dark:text-white"
                  min="1"
                />
              </label>
            </div>
            <div>
              <label htmlFor="subject" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Subject
                <select
                  name="subject"
                  id="subject"
                  value={lessonRequest.subject}
                  onChange={handleChange}
                  className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-600 dark:border-gray-500 dark:text-white"
                >
                  {availableSubjects.map((subject) => (
                    <option key={subject} value={subject}>{subject}</option>
                  ))}
                </select>
              </label>
            </div>
            <div>
              <label htmlFor="level" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Level
                <select
                  name="level"
                  id="level"
                  value={lessonRequest.level}
                  onChange={handleChange}
                  className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-600 dark:border-gray-500 dark:text-white"
                >
                  {availableLevels.map((level) => (
                    <option key={level} value={level}>{level}</option>
                  ))}
                </select>
              </label>
            </div>
          </div>
          <div className="flex items-center justify-between p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
            <div>
              <button
                onClick={bookLesson}
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Propose Lesson
              </button>
              <button
                onClick={hideState}
                type="button"
                className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                Quit
              </button>
            </div>
            <div className="mx-8 font-bold dark:text-white">
              £
              {lessonRequest.cost}
              {' '}
              total
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
