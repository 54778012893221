import React from 'react';
import { SubjectMapping } from '../../../types/Subjects';
import { LessonProposal } from '../../../types/collections/LessonProposal';

export interface SubjectSelectionProps {
  nextStep: () => void;
  lessonPlan: LessonProposal,
  setLessonPlan: React.Dispatch<React.SetStateAction<LessonProposal>>
}

export const SubjectSelection: React.FC<SubjectSelectionProps> = ({
  nextStep,
  lessonPlan,
  setLessonPlan,
}) => (
  <div
    className="max-w-screen-lg w-full flex flex-col justify-center items-start p-4  text-gray-500 dark:text-gray-400 gap-2"
  >
    <h2 className="my-4 text-4xl font-bold text-gray-900 dark:text-warning">
      Schedule a Lesson
    </h2>
    <p>
      Book a new session, we&apos;ll match you up with a tutor who&apos;s a good fit for you!
    </p>

    <div className="w-full flex justify-center gap-2 flex-wrap max-w-[80%] m-auto">
      {SubjectMapping.map((subject) => (
        <button
          type="button"
          key={subject.id}
          onClick={() => {
            setLessonPlan((prevState) => ({
              ...prevState,
              subject: subject.label,
            }));
          }}
          style={{
            backgroundImage: `linear-gradient(to bottom right, ${subject.colors.from}, ${subject.colors.to})`,
          }}
          className="relative inline-flex items-center justify-center p-0.5 mb-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group hover:text-white dark:text-white focus:ring-4 focus:outline-none"
        >
          <span className={`relative px-5 py-2.5 transition-all ease-in duration-75 rounded-md ${
            lessonPlan.subject === subject.label ? 'text-white' : 'bg-white dark:bg-gray-900 group-hover:bg-opacity-0'
          }`}
          >
            {subject.label}
          </span>
        </button>
      ))}
    </div>
    <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">
      We currently only teach a select few, to request a new subject
      <a href="/contact" className="font-medium text-blue-600 hover:underline dark:text-blue-500"> Contact Us</a>
      .
    </p>

    <p className="text-gray-900 dark:text-white font-bold text-xl">What level is this subject?</p>

    <form className="max-w-lg mx-auto w-full">
      <label htmlFor="level" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        Select your level
        <select
          value={lessonPlan.level}
          onChange={(event) => {
            setLessonPlan((prevState) => ({
              ...prevState,
              level: event.target.value,
            }));
          }}
          id="level"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option>Primary School</option>
          <option>GCSE Specific</option>
          <option>A-Level</option>
          <option>Undergrad</option>
          <option>Postgrad</option>
        </select>
      </label>
    </form>

    <div className="w-full flex justify-end items-center">
      <button
        type="button"
        onClick={() => { nextStep(); }}
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
      >
        Next
      </button>

    </div>
  </div>
);
