import React, { useState } from 'react';
import { UserDocument } from '../../../types/collections/User';
import { SubjectMapping } from '../../../types/Subjects';
import FirebaseFirestoreService from '../../../services/database/strategies/FirebaseFirestoreService';

export interface EditUserInformationProps {
  userDoc: UserDocument;
}

// type Subject = {
//   id: string;
//   label: string;
//   colors: {
//     from: string;
//     to: string;
//   };
// };
// const subjects: Subject[] = [
//   { id: 'step', label: 'STEP', colors: { from: '#8e24aa', to: '#2196f3' } },
//   { id: 'science', label: 'English', colors: { from: '#00bcd4', to: '#2196f3' } },
//   { id: 'mathematics', label: 'Mathematics', colors: { from: '#66bb6a', to: '#1e88e5' } },
//   { id: 'computer-science', label: 'Computer Science'
//   , colors: { from: '#4db6ac', to: '#dce775' } },
//   { id: 'machine-learning', label: 'Machine Learning',
//   colors: { from: '#ef9a9a', to: '#e57373' } },
//   { id: 'physics', label: 'Physics', colors: { from: '#9c27b0', to: '#e91e63' } },
//   { id: 'chemistry', label: 'Chemistry', colors: { from: '#e91e63', to: '#ff7043' } },
// ];

export const EditUserInformation: React.FC<EditUserInformationProps> = ({ userDoc }) => {
  const [localUserDoc, setLocalUserDoc] = useState<UserDocument>(userDoc);
  const [errors, setErrors] = useState({
    fullName: '',
    addressFirstLine: '',
    addressSecondLine: '',
    phoneNumber: '',
    city: '',
    postCode: '',
    email: '',
    gcseYear: '',
  });

  const validateForm = (newDocument: UserDocument) => {
    const valid = true;
    const errorForm = {
      fullName: '',
      addressFirstLine: '',
      addressSecondLine: '',
      phoneNumber: '',
      city: '',
      postCode: '',
      email: '',
      gcseYear: '',
    };

    if (newDocument.fullName === '') {
      errorForm.fullName = 'Set a full name';
    }

    const pattern = /^\+?[0-9]{2}\s?0?[0-9]{5}\s?[0-9]{5}$/;
    if (newDocument.phoneNumber !== '' && !pattern.test(newDocument.phoneNumber)) {
      errorForm.phoneNumber = 'Phone number must match pattern: +44 075760 501000';
    }

    const emailPattern = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/;
    if (newDocument.email !== '' && !emailPattern.test(newDocument.email)) {
      errorForm.email = 'Not a valid email address';
    }

    if (newDocument.gcseYear && newDocument.gcseYear !== '' && (parseInt(newDocument.gcseYear, 10) <= 1960 || parseInt(newDocument.gcseYear, 10) >= 2060)) {
      errorForm.gcseYear = '... Be reasonable';
    }

    if (newDocument.addressFirstLine === '') {
      errorForm.addressFirstLine = 'First line cannot be empty';
    }

    if (newDocument.city === '') {
      errorForm.city = 'City must be entered';
    }

    if (newDocument.postcode === '') {
      errorForm.postCode = 'Postcode must be entered';
    }
    // Set errors state
    setErrors(errorForm);
    return valid;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const newDocument: UserDocument = { ...localUserDoc, [name]: value };
    if (validateForm(newDocument)) {
      setLocalUserDoc((prevForm) => ({ ...prevForm, [name]: value }));
    }
  };

  const toggleSubject = (id: string) => {
    setLocalUserDoc((prevUserDocument) => (prevUserDocument.subjects.includes(id)
      ? {
        ...prevUserDocument,
        subjects: prevUserDocument.subjects.filter((subjectId) => subjectId !== id),
      }
      : {
        ...prevUserDocument,
        subjects: [...prevUserDocument.subjects, id],
      }
    ));
  };

  return (
    <div className="py-8">
      <h1 className="text-2xl font-bold mb-4">Edit User Information</h1>
      <p>Have a look through all of your information and edit it at a glance.</p>
      <div className="w-full my-5 h-[1px] bg-gray-500 rounded-full" />

      <form className="text-left">
        <div className="mb-6">
          <h1 className="text-xl font-bold mb-4">Account Type</h1>
          <div className="flex gap-2 my-2">
            <button
              type="button"
              onClick={() => { setLocalUserDoc({ ...localUserDoc, accountType: 'tutor' }); }}
              className={`max-w-xs ${localUserDoc.accountType === 'tutor' ? 'bg-blue-50 dark:bg-gray-900' : 'bg-white dark:bg-gray-800'} border ${localUserDoc.accountType === 'tutor' ? 'border-blue-200 dark:border-blue-700' : 'border-gray-200 dark:border-gray-700'} rounded-lg shadow px-4 py-2`}
            >
              Tutr
            </button>

            <button
              type="button"
              onClick={() => { setLocalUserDoc({ ...localUserDoc, accountType: 'student' }); }}
              className={`max-w-xs ${localUserDoc.accountType !== 'tutor' ? 'bg-blue-50 dark:bg-gray-900' : 'bg-white dark:bg-gray-800'} border ${localUserDoc.accountType !== 'tutor' ? 'border-blue-200 dark:border-blue-700' : 'border-gray-200 dark:border-gray-700'} rounded-lg shadow px-4 py-2`}
            >
              Student
            </button>
          </div>

          <h1 className="text-xl font-bold mb-4">Personal Information</h1>
          <label htmlFor="fullName" className="block text-sm font-medium text-gray-900 dark:text-white">
            Full Name
            <input
              type="text"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              id="fullName"
              name="fullName"
              required
              placeholder="Full Name"
              value={localUserDoc.fullName}
              onChange={handleInputChange}
            />
            {errors.fullName && <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.fullName}</p>}
          </label>
        </div>

        <div className="mb-6">
          <label htmlFor="phone-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Phone number:
            <div className="relative">
              <div className="absolute inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 19 18"
                >
                  <path
                    d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="phone-input"
                aria-describedby="helper-text-explanation"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                pattern="\+?[0-9]{2}\s?[0-9]{5}\s?[0-9]{6}"
                placeholder="+44 07576 050100"
                required
                name="phoneNumber"
                value={localUserDoc.phoneNumber}
                onChange={handleInputChange}
              />
            </div>
          </label>
          {errors.phoneNumber && <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.phoneNumber}</p>}
          <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">
            Select a phone number
            that matches the format.
          </p>
        </div>

        <div className="mb-6">
          <label htmlFor="helper-text" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Your email
            <input
              type="email"
              id="helper-text"
              aria-describedby="helper-text-explanation"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="name@tutr.ai"
              name="email"
              value={localUserDoc.email}
              onChange={handleInputChange}
            />
            {errors.email && <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.email}</p>}
            <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">
              We’ll never share your details. Read our
              <a href="/privacy-policy" className="font-medium text-blue-600 hover:underline dark:text-blue-500"> Privacy Policy</a>
              .
            </p>
          </label>
        </div>

        <div className="mb-6">
          <label htmlFor="helper-text" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            When will/did you take your GCSE&apos;s
            Select a Year
            <input
              type="number"
              placeholder="YYYY"
              min="1999"
              max="2020"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              name="gcseYear"
              value={localUserDoc.gcseYear}
              onChange={handleInputChange}
            />
            {errors.gcseYear && <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.gcseYear}</p>}
          </label>
        </div>

        {
          localUserDoc.accountType === 'tutor' && (
          <div className="mb-6">
            <label htmlFor="fullName" className="block text-sm font-medium text-gray-900 dark:text-white">
              First line of your address
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                id="addressFirstLine"
                name="addressFirstLine"
                required
                placeholder="Address First Line"
                value={localUserDoc.addressFirstLine}
                onChange={handleInputChange}
              />
              {errors.addressFirstLine
                && <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.addressFirstLine}</p>}
            </label>
          </div>
          )
      }

        {
          localUserDoc.accountType === 'tutor' && (
          <div className="mb-6">
            <label htmlFor="addressSecondLine" className="block text-sm font-medium text-gray-900 dark:text-white">
              Second line of your address
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                id="addressSecondLine"
                name="addressSecondLine"
                required
                placeholder="Second line of your address"
                value={localUserDoc.addressSecondLine}
                onChange={handleInputChange}
              />
              {errors.addressSecondLine
                && <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.addressSecondLine}</p>}
            </label>
          </div>
          )
      }

        {
          localUserDoc.accountType === 'tutor' && (
          <div className="grid gap-6 mb-6 md:grid-cols-2">
            <div>
              <label htmlFor="helper-text" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                City
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="London"
                  name="city"
                  value={localUserDoc.city}
                  onChange={handleInputChange}
                />
                {errors.city && <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.city}</p>}
              </label>
            </div>
            <div>
              <label htmlFor="helper-text" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Postcode
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="SW67PS"
                  name="postcode"
                  value={localUserDoc.postcode}
                  onChange={handleInputChange}
                />
                {errors.postCode && <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.postCode}</p>}
              </label>
            </div>
          </div>
          )
      }

        <h1 className="text-xl font-bold mb-4">Subjects</h1>
        <div className="w-full flex justify-center gap-2 flex-wrap max-w-[80%] m-auto">
          {SubjectMapping.map((subject) => (
            <button
              type="button"
              key={subject.id}
              onClick={() => toggleSubject(subject.id)}
              style={{
                backgroundImage: `linear-gradient(to bottom right, ${subject.colors.from}, ${subject.colors.to})`,
              }}
              className="relative inline-flex items-center justify-center p-0.5 mb-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group hover:text-white dark:text-white focus:ring-4 focus:outline-none"
            >
              <span className={`relative px-5 py-2.5 transition-all ease-in duration-75 rounded-md ${
                localUserDoc.subjects.includes(subject.id) ? 'text-white' : 'bg-white dark:bg-gray-900 group-hover:bg-opacity-0'
              }`}
              >
                {subject.label}
              </span>
            </button>
          ))}

          <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">
            We currently only teach a select few, to request a new subject
            <a href="/contact-us" className="font-medium text-blue-600 hover:underline dark:text-blue-500"> Contact Us</a>
            .
          </p>
        </div>

      </form>
      <div className="w-full flex items-center justify-center gap-2 my-8">
        <button
          type="button"
          onClick={() => {
            FirebaseFirestoreService.updateDocument(
              'users',
              userDoc.id,
              localUserDoc,
              () => {
                window.location.reload();
              },
              () => {
                console.error('Failed to Update Document');
              },
            );
          }}
          className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800 disabled:bg-gray-500 dark:disabled:bg-gray-800"
        >
          Update Personal Information
        </button>
      </div>
    </div>
  );
};
