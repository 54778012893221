import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signOut,
  User as FirebaseUser,
  updateProfile,
} from 'firebase/auth';
import AuthService from '../AuthenticationInterface';
import app from '../../../config/firebaseConfig';
import { User } from '../../../types/User';

const auth = getAuth(app);

const FirebaseAuthService: AuthService = {
  async login(email: string, password: string) {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return {
      uid: userCredential.user.uid,
      name: userCredential.user.displayName || '',
      emailVerified: userCredential.user.emailVerified,
      email: userCredential.user.email || '',
    };
  },

  async register(email: string, name:string, password: string) {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    if (userCredential.user) {
      await updateProfile(userCredential.user, {
        displayName: name,
      });
    }
    return {
      uid: userCredential.user.uid,
      name: userCredential.user.displayName || '',
      emailVerified: userCredential.user.emailVerified,
      email: userCredential.user.email || '',
    };
  },

  async logout() {
    await signOut(auth);
  },

  onAuthStateChanged(callback: (user: User | null) => void) {
    return onAuthStateChanged(auth, (firebaseUser : FirebaseUser | null) => {
      if (firebaseUser) {
        callback({
          uid: firebaseUser.uid,
          name: firebaseUser.displayName || '',
          emailVerified: firebaseUser.emailVerified,
          email: firebaseUser.email || '',
        });
      } else {
        callback(null);
      }
    });
  },

  async sendVerificationEmail() {
    const user = auth.currentUser;
    if (user) {
      await sendEmailVerification(user);
    } else {
      throw new Error('No user is currently signed in.');
    }
  },

  async checkEmailVerification() {
    const user = auth.currentUser;
    if (user) {
      await user.reload(); // Refresh the user object to get the latest emailVerified status
      return user.emailVerified;
    }
    return false;
  },

  async sendPasswordResetEmail(email: string) {
    await sendPasswordResetEmail(auth, email);
  },
};

export default FirebaseAuthService;
