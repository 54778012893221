import React from 'react';

interface StepProps {
  label: string;
  index: number;
  totalSteps: number;
  isCurrent: boolean;
}

const Step: React.FC<StepProps> = ({
  label, index, totalSteps, isCurrent,
}) => (
  <li className="relative mb-6 w-[100px]">
    <div className="flex items-center">
      <div className={`z-10 flex items-center justify-center w-6 h-6 ${isCurrent ? 'bg-blue-200' : 'bg-gray-200'} rounded-full ring-0 ring-white ${isCurrent ? 'dark:bg-blue-900' : 'dark:bg-gray-700'} sm:ring-8 dark:ring-gray-900 shrink-0`}>
        <span className={`flex w-3 h-3 ${isCurrent ? 'bg-blue-600' : 'bg-gray-900'} rounded-full`} />
      </div>
      {index < totalSteps - 1 && (
      <div className="flex w-full bg-gray-200 h-0.5 dark:bg-gray-200" />
      )}
    </div>
    <div className="mt-3">
      <h3 className="text-sm text-gray-700 dark:text-white max-w-[50px]">{label}</h3>
    </div>
  </li>
);

interface StepperProps {
  steps: string[];
  currentStep: number;
}

const StepperIndicator: React.FC<StepperProps> = ({ steps, currentStep }) => (
  <ol className="flex my-2">
    {steps.map((label, index) => (
      <Step
        key={`key-${label}`}
        label={label}
        index={index}
        totalSteps={steps.length}
        isCurrent={index === currentStep}
      />
    ))}
  </ol>
);

export default StepperIndicator;
