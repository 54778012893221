import React, { useState } from 'react';
import { Day, ScheduledEvent } from '../../types/Calendar';

export interface DayProps {
  day: Day;
  // eslint-disable-next-line react/require-default-props
  scheduledEvent?: ScheduledEvent;
}

export const DayButton: React.FC<DayProps> = ({ day, scheduledEvent }) => {
  const [showOptions, setShowOptions] = useState(false);

  const isToday = day.date.getDate() === new Date().getDate()
    && day.date.getMonth() === new Date().getMonth()
    && day.date.getFullYear() === new Date().getFullYear();

  const isScheduled = !!scheduledEvent;

  return (
    <div
      className="relative"
      onMouseEnter={() => setShowOptions(true)}
      onMouseLeave={() => setShowOptions(false)}
    >
      <button
        type="button"
        className={`w-8 h-8 border border-gray-200 rounded-lg shadow dark:border-gray-700 flex justify-center items-center dark:text-white 
          ${isToday ? 'bg-blue-200 dark:bg-blue-500' : ''}
          ${isScheduled ? 'bg-green-200 dark:bg-green-600' : 'hover:bg-gray-100 dark:hover:bg-gray-700 dark:bg-gray-800'}`}
      >
        {day.date.getDate()}
      </button>
      {showOptions && (
        <div className="absolute left-0 mt-2 py-2 w-48 bg-white border border-gray-300 rounded-lg shadow-xl z-50">
          {scheduledEvent ? (
            scheduledEvent.component
          ) : (
            <ul>
              <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Schedule a new meeting</li>
              <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Set unavailable</li>
              <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">View events</li>
            </ul>
          )}
        </div>
      )}
    </div>
  );
};
