import { DocumentData } from 'firebase/firestore';

export type AccountType = 'student' | 'tutor';

export interface UserDocument {
  accountType: AccountType,
  uid: string,
  fullName: string,
  phoneNumber: string,
  email: string,
  addressFirstLine: string,
  addressSecondLine: string,
  city: string,
  postcode: string,
  gcseYear: string,
  subjects: string[],
  id: string,
  stripeCustomerId?: string,
}

export const EmptyUserDocument: UserDocument = {
  accountType: 'student',
  uid: '',
  fullName: '',
  phoneNumber: '',
  email: '',
  addressFirstLine: '',
  addressSecondLine: '',
  city: '',
  postcode: '',
  gcseYear: '',
  subjects: [''],
  id: '',
};

export function documentToUserDoc(docData: DocumentData): UserDocument {
  return {
    id: docData.id,
    accountType: docData.accountType,
    uid: docData.uid,
    fullName: docData.fullName,
    phoneNumber: docData.phoneNumber,
    email: docData.email,
    addressFirstLine: docData.addressFirstLine,
    addressSecondLine: docData.addressSecondLine,
    city: docData.city,
    postcode: docData.postcode,
    gcseYear: docData.gcseYear,
    subjects: docData.subjects,
    stripeCustomerId: docData.stripeCustomerId,
  };
}
