import React from 'react';
import { UserDocument } from '../../../../types/collections/User';

export interface AccountTypeProps {
  userDocument: UserDocument,
  setUserDocument: React.Dispatch<React.SetStateAction<UserDocument>>,
  nextStep: () => void,
}

export const AccountType: React.FC<AccountTypeProps> = ({
  userDocument,
  setUserDocument,
  nextStep,
}) => (
  <div className="mx-auto max-w-screen-sm text-center">
    <h2 className="mb-4 text-4xl font-extrabold leading-tight text-gray-900 dark:text-white">Who are you?</h2>
    <p className="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">Are you a Tütr or a Student?</p>

    <div className="flex gap-2">
      <button
        type="button"
        onClick={() => {
          setUserDocument({ ...userDocument, accountType: 'tutor' });
          nextStep();
        }}
        className={`max-w-sm ${userDocument.accountType === 'tutor' ? 'bg-blue-50 dark:bg-gray-900' : 'bg-white dark:bg-gray-800'} border ${userDocument.accountType === 'tutor' ? 'border-blue-200 dark:border-blue-700' : 'border-gray-200 dark:border-gray-700'}  rounded-lg shadow `}
      >
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <div>
          <img className="rounded-t-lg" src="https://notioly.com/wp-content/uploads/2024/03/362.Growth.png" alt="" />
        </div>
        <div className="p-5">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Tutr</h5>
          <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Want to earn a bit of extra income? Have you recently completed your A-Levels or graduated from a top university?</p>
          <button type="button" className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Tutr
          </button>
        </div>
      </button>

      <button
        type="button"
        onClick={() => {
          setUserDocument({ ...userDocument, accountType: 'student' });
          nextStep();
        }}
        className={`max-w-sm ${userDocument.accountType !== 'tutor' ? 'bg-blue-50 dark:bg-gray-900' : 'bg-white dark:bg-gray-800'} border ${userDocument.accountType !== 'tutor' ? 'border-blue-200 dark:border-blue-700' : 'border-gray-200 dark:border-gray-700'}  rounded-lg shadow `}
      >
        <img className="rounded-t-lg" src="https://notioly.com/wp-content/uploads/2024/03/361.Reading-The-News.png" alt="" />
        <div className="p-5">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Student</h5>
          <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Trying to improve your grades at school? Use </p>
          <button type="button" className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Student
          </button>
        </div>
      </button>

    </div>
  </div>
);
