import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ScrollableContentLayout from '../layouts/ScrollableContentLayout';
import ChatList from '../components/page-components/messages/ChatList';
import { Chat } from '../types/collections/Chats';
import ChatWindow from '../components/page-components/messages/ChatWindow';
import { ScheduleLessons } from '../components/page-components/messages/schedule-lesson/ScheduleLessons';
import FirebaseFirestoreService from '../services/database/strategies/FirebaseFirestoreService';
import { useAuth } from '../contexts/AuthContext';
import { TutorProfileType } from '../types/TutorProfile';

export interface MessagesProps {}

export const Messages: React.FC<MessagesProps> = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [allChats, setAllChats] = useState<Chat[]>([]);
  const [selectedChat, setSelectedChat] = useState<Chat | undefined>(undefined);
  const [
    selectedChatProfileCard,
    setSelectedProfileCard,
  ] = useState<TutorProfileType | undefined>(undefined);
  const [modalShown, setModalShown] = useState(false);
  const { authState } = useAuth();

  // Fetch chats
  useEffect(() => {
    const fetchChats = async () => {
      if (authState.user) {
        const fetchedChats: Chat[] = [
          {
            id: 'example-tutr-ai-model',
            participant1Id: authState.user.uid,
            participant2Id: 'tutor-ai-id',
            participant1Name: authState.user?.uid,
            participant2Name: 'Tütr AI Assistant',
            lastMessage: 'Ask me anything!',
            lastMessageTime: Date.now(),
          },
          {
            id: 'example-tutor-support-model',
            participant1Id: authState.user.uid,
            participant2Id: 'tutor-ai-support',
            participant1Name: authState.user?.uid,
            participant2Name: 'Tütr AI Support',
            lastMessage: 'Need Help?',
            lastMessageTime: Date.now(),
          },
        ];

        try {
          const participant1Chats = await new Promise<Chat[]>((resolve, reject) => {
            FirebaseFirestoreService.queryDocuments(
              'chat',
              'participant1Id',
              authState.user!.uid,
              'lastMessageTime',
              100,
              undefined,
              (docs) => resolve(docs.map((elem) => elem as Chat)),
              reject,
            );
          });

          const participant2Chats = await new Promise<Chat[]>((resolve, reject) => {
            FirebaseFirestoreService.queryDocuments(
              'chat',
              'participant2Id',
              authState.user!.uid,
              'lastMessageTime',
              100,
              undefined,
              (docs) => resolve(docs.map((elem) => elem as Chat)),
              reject,
            );
          });

          fetchedChats.push(...participant1Chats, ...participant2Chats);

          const chatMap = new Map();
          fetchedChats.forEach((chat) => {
            const key1 = `${chat.participant1Id}_${chat.participant2Id}`;
            const key2 = `${chat.participant2Id}_${chat.participant1Id}`;
            if (!chatMap.has(key1) && !chatMap.has(key2)) {
              chatMap.set(key1, chat);
            }
          });

          const uniqueChats: Chat[] = Array.from(chatMap.values());
          uniqueChats.sort((elem1, elem2) => elem2.lastMessageTime - elem1.lastMessageTime);
          setAllChats(uniqueChats);

          // Set selected chat based on URL param after fetching chats
          const chatId = searchParams.get('chatId');
          if (chatId) {
            const chat = uniqueChats.find((c) => c.id === chatId);
            if (chat) {
              setSelectedChat(chat);
            }
          }
        } catch (error) {
          console.error('Error fetching chats:', error);
        }
      }
    };

    fetchChats();
  }, [authState, searchParams]);

  // Update search params when selected chat changes
  useEffect(() => {
    if (selectedChat) {
      setSearchParams({ chatId: selectedChat.id });
      FirebaseFirestoreService.getDocument(
        'tutor-profile',
        selectedChat.participant1Id,
        (doc) => {
          if (doc) {
            console.log('Successfully retrieved the doc');
            setSelectedProfileCard(doc as TutorProfileType);
          }
        },
        (error) => {
          console.error(error);
        },
      );
      FirebaseFirestoreService.getDocument(
        'tutor-profile',
        selectedChat.participant2Id,
        (doc) => {
          if (doc) {
            console.log('Successfully retrieved the doc');
            setSelectedProfileCard(doc as TutorProfileType);
          }
        },
        (error) => {
          console.error(error);
        },
      );
    }
  }, [selectedChat, setSearchParams]);

  return (
    <ScrollableContentLayout footer={false}>
      <div className="px-4 mx-auto lg:px-6 flex h-[90vh] justify-evenly">
        {modalShown && selectedChatProfileCard && selectedChat && (
          <ScheduleLessons
            hideState={() => { setModalShown(false); }}
            chat={selectedChat}
            tutorProfileType={selectedChatProfileCard}
          />
        )}

        <ChatList
          chats={allChats}
          onSelectChat={(chat: Chat) => { setSelectedChat({ ...chat } as Chat); }}
          selectedChat={selectedChat}
        />
        {
          selectedChat
            ? (
              <ChatWindow
                chat={selectedChat}
                newLesson={() => {
                  setModalShown(true);
                  console.log(selectedChatProfileCard);
                  console.log(selectedChat);
                  console.log(modalShown);
                }}
              />
            )
            : (
              <div className="w-2/3 h-full flex justify-center items-center gap-2 flex-col text-gray-800 dark:text-white">
                <svg className="w-16 h-16" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                  <path d="m3.62 6.389 8.396 6.724 8.638-6.572-7.69-4.29a1.975 1.975 0 0 0-1.928 0L3.62 6.39Z" />
                  <path d="m22 8.053-8.784 6.683a1.978 1.978 0 0 1-2.44-.031L2.02 7.693a1.091 1.091 0 0 0-.019.199v11.065C2 20.637 3.343 22 5 22h14c1.657 0 3-1.362 3-3.043V8.053Z" />
                </svg>
                <p className="font-bold text-lg">No chat selected</p>
                <p>Pick a chat from the left-hand side or schedule a new lesson!</p>
              </div>
            )
        }
      </div>
    </ScrollableContentLayout>
  );
};
