import React, { useEffect, useState } from 'react';
import { LessonProposal } from '../../../types/collections/LessonProposal';
import { TutorProfileType } from '../../../types/TutorProfile';
import { UserDocument } from '../../../types/collections/User';
import FirebaseFirestoreService from '../../../services/database/strategies/FirebaseFirestoreService';
import { TutorProfileCard } from '../../tutor-profile-card/TutorProfileCard';

export interface SelectTutorProps {
  previousStep: () => void,
  nextStep: () => void,
  lessonPlan: LessonProposal,
  selectTutor: (tutor: TutorProfileType, tutorUserDoc: UserDocument) => void,
  selectedTutorUserDoc: UserDocument | undefined,
}

type TutorProfileAndUserDoc = {
  tutorProfile: TutorProfileType,
  userDoc: UserDocument
}

export const SelectTutor: React.FC<SelectTutorProps> = (
  {
    previousStep,
    nextStep,
    lessonPlan,
    selectTutor,
    selectedTutorUserDoc,
  },
) => {
  const [filteredTutors, setFilteredTutors] = useState<TutorProfileAndUserDoc[]>([]);
  console.log(selectedTutorUserDoc);

  useEffect(() => {
    FirebaseFirestoreService.getAllDocuments('tutor-profile', (docs) => {
      if (docs && docs.length > 0) {
        const tutorProfileDocs = docs.map((elem) => elem as TutorProfileType);
        const seenUserIds = new Set<string>();
        for (let index = 0; index < docs.length; index += 1) {
          if (docs[index] && docs[index].id) {
            if (docs[index].id && !seenUserIds.has(docs[index].id!)) {
              seenUserIds.add(docs[index].id!);
              FirebaseFirestoreService.queryDocuments(
                'users',
                'uid',
                docs[index].id!,
                'fullName',
                1,
                undefined,
                (userDocs) => {
                  if (userDocs.length > 0) {
                    const userDocument = userDocs[0] as UserDocument;
                    console.log('Entered here', {
                      tutorProfile: tutorProfileDocs[index],
                      userDoc: userDocument,
                    });
                    setFilteredTutors((prevState) => {
                      // Check if this tutor is already in the state
                      if (!prevState.some((tutor) => tutor.userDoc.uid === userDocument.uid)) {
                        return [...prevState, {
                          tutorProfile: tutorProfileDocs[index],
                          userDoc: userDocument,
                        }];
                      }
                      return prevState; // If the tutor is already in the state, don't add it again
                    });
                  }
                },
                (error) => {
                  console.log('Failed to get the documents...', docs[index].id, error);
                },
              );
            }
          }
        }
      }
    });
  }, [lessonPlan]);

  useEffect(() => {
    console.log('updated tutors', filteredTutors);
  }, [filteredTutors]);
  return (
    <div
      className="max-w-screen-lg w-full flex flex-col justify-center items-start p-4  text-gray-500 dark:text-gray-400 gap-2"
    >
      <h2 className="my-4 text-4xl font-bold text-gray-900 dark:text-warning">
        Tutor Preferences
      </h2>

      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {
            filteredTutors.map(
              (elem) => (
                <TutorProfileCard
                  userDoc={elem.userDoc}
                  tutorProfileType={elem.tutorProfile}
                  pickTutor={() => {
                    selectTutor(elem.tutorProfile, elem.userDoc);
                  }}
                  selected={selectedTutorUserDoc && elem.userDoc.uid === selectedTutorUserDoc.uid}
                />
              ),
            )
          }
        </div>
      </div>

      <div className="w-full flex justify-between items-center">
        <button
          type="button"
          onClick={() => { previousStep(); }}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        >
          Back
        </button>
        <button
          type="button"
          onClick={() => { nextStep(); }}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        >
          Next
        </button>
      </div>
    </div>
  );
};
