import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import UnauthenticatedLanding from '../components/page-components/landing/UnauthenticatedLanding';
import FirebaseFirestoreService from '../services/database/strategies/FirebaseFirestoreService';
import OnboardingLanding from '../components/page-components/landing/OnboardingLanding';
import AuthenticatedLanding from '../components/page-components/landing/AuthenticatedLanding';
import { UserDocument, EmptyUserDocument } from '../types/collections/User';

type landingPageType = 'AuthenticatedLanding' | 'UnauthenticatedLanding' | 'OnboardingLanding'

const Landing :React.FC = () => {
  const [landingPageState, setLandingPageState] = useState<landingPageType>('UnauthenticatedLanding');
  const [userDoc, setUserDoc] = useState<UserDocument>(EmptyUserDocument);
  const { authState } = useAuth();
  // const [userDocument, setUserDocument] = useState();

  useEffect(() => {
    // Check authentication status
    if (authState.isAuthenticated) {
      // Check the User Document
      FirebaseFirestoreService.queryDocuments(
        'users',
        'uid',
        authState.user?.uid as string,
        'uid',
        1,
        undefined,
        (doc) => {
          if (doc.length > 0) {
            setUserDoc(doc[0] as UserDocument);
            setLandingPageState('AuthenticatedLanding');
          } else {
            setLandingPageState('OnboardingLanding');
          }
        },
        () => {
          setLandingPageState('UnauthenticatedLanding');
        },
      );
      setLandingPageState('AuthenticatedLanding');
    } else {
      setLandingPageState('UnauthenticatedLanding');
    }
  }, [authState]);

  if (landingPageState === 'UnauthenticatedLanding') {
    return <UnauthenticatedLanding />;
  } if (landingPageState === 'OnboardingLanding') {
    return <OnboardingLanding />;
  }
  return <AuthenticatedLanding userDoc={userDoc} />;
};

export default Landing;
