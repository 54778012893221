import React, { useState } from 'react';
import { Chat } from '../../../types/collections/Chats';
import ChatListItem from './ChatListItem';

interface ChatListProps {
  chats: Chat[];
  onSelectChat: (chat: Chat) => void;
  selectedChat: Chat | undefined;
}

const ChatList: React.FC<ChatListProps> = ({ chats, onSelectChat, selectedChat }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredChats = chats.filter(
    (chat) => chat.participant1Name.toLowerCase().includes(searchTerm.toLowerCase())
    || chat.participant2Name.toLowerCase().includes(searchTerm.toLowerCase())
    || chat.lastMessage.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <div className="w-full max-w-sm bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden my-2 dark:text-white">
      <div className="p-4">
        <div className="flex items-center bg-gray-100 dark:bg-gray-500 rounded-full p-2 mb-4">
          <div className="flex-grow flex items-center px-2">
            <input
              type="text"
              placeholder="Search message"
              className="bg-transparent w-full outline-none text-gray-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <svg
              className="w-6 h-6 text-gray-800"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth="2"
                d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
        </div>
        <div>
          <div className="flex justify-between items-center mb-2">
            <h2 className="text-lg font-semibold">Message</h2>
            <button className="text-gray-400 dark:text-white" type="button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="12" cy="12" r="1" />
                <circle cx="19" cy="12" r="1" />
                <circle cx="5" cy="12" r="1" />
              </svg>
            </button>
          </div>
          <ul>
            {filteredChats.map((chat) => (
              <li key={chat.id} className="mb-2">
                <ChatListItem
                  chat={chat}
                  onSelectChat={onSelectChat}
                  isSelected={selectedChat?.id === chat.id}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ChatList;
