import React, { useEffect, useState } from 'react';
import { Check, Info, X } from 'lucide-react';
import {
  TutorProfileType, DefaultLevels, DefaultPricing,
} from '../../../../types/TutorProfile';
import { SubjectMapping, Subjects } from '../../../../types/Subjects';

interface TutorProfileFormProps {
  tutorProfileType: TutorProfileType;
  onSave: (data: TutorProfileType) => void;
}

const TutorProfileForm: React.FC<TutorProfileFormProps> = ({ tutorProfileType, onSave }) => {
  const [formData, setFormData] = useState<TutorProfileType>(tutorProfileType);
  const [showTooltip, setShowTooltip] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    setFormData(tutorProfileType);
  }, [tutorProfileType]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAvailabilityChange = (day: string, timeType: 'startTime' | 'endTime', value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      availability: {
        ...prevData.availability,
        [day]: {
          ...prevData.availability[day],
          [timeType]: value,
        },
      },
    }));
  };

  const handlePricingChange = (subject: Subjects, level: string, price: number | '') => {
    const minPrice = DefaultPricing[level];
    const validPrice = price === '' ? '' : Math.max(Number(price), minPrice);

    setFormData((prevData) => {
      const newPricing = { ...prevData.pricing };
      if (!newPricing[subject]) {
        newPricing[subject] = {};
      }
      if (validPrice === '') {
        delete newPricing[subject][level];
        if (Object.keys(newPricing[subject]).length === 0) {
          delete newPricing[subject];
        }
      } else {
        newPricing[subject][level] = validPrice;
      }
      return { ...prevData, pricing: newPricing };
    });
  };

  const toggleTooltip = (subject: string, level: string) => {
    setShowTooltip((prev) => ({
      ...prev,
      [`${subject}-${level}`]: !prev[`${subject}-${level}`],
    }));
  };

  const toggleSubjectLevel = (subject: Subjects, level: string) => {
    setFormData((prevData) => {
      const newPricing = { ...prevData.pricing };
      if (!newPricing[subject]) {
        newPricing[subject] = { [level]: DefaultPricing[level] };
      } else {
        const updatedSubject = { ...newPricing[subject] };
        if (updatedSubject[level]) {
          delete updatedSubject[level];
        } else {
          updatedSubject[level] = DefaultPricing[level];
        }

        if (Object.keys(updatedSubject).length === 0) {
          delete newPricing[subject];
        } else {
          newPricing[subject] = updatedSubject;
        }
      }
      return { ...prevData, pricing: newPricing };
    });
  };

  return (
    <form onSubmit={(e) => { e.preventDefault(); onSave(formData); }} className="space-y-6">
      {/* Basic Information */}
      <div className="space-y-4">
        <div>
          <label htmlFor="activeStudents" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Active Students
          </label>
          <input
            type="number"
            id="activeStudents"
            name="activeStudents"
            value={formData.activeStudents}
            onChange={handleChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Number of active students"
          />
        </div>
        <div>
          <label htmlFor="experienceYears" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Years of Experience
          </label>
          <input
            type="number"
            id="experienceYears"
            name="experienceYears"
            value={formData.experienceYears}
            onChange={handleChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Years of teaching experience"
          />
        </div>
        <div>
          <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Description
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            rows={4}
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Write a brief description about yourself"
          />
        </div>
        <div>
          <label htmlFor="biography" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Biography (Markdown supported)
          </label>
          <textarea
            id="biography"
            name="biography"
            value={formData.biography}
            onChange={handleChange}
            rows={6}
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Write your detailed biography here (Markdown supported)"
          />
        </div>
        <div>
          <label htmlFor="cvLink" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            CV Link
          </label>
          <input
            type="text"
            id="cvLink"
            name="cvLink"
            value={formData.cvLink}
            onChange={handleChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Link to your CV"
          />
        </div>
      </div>

      {/* Availability */}
      <div className="space-y-4">
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Availability</h3>
        {Object.entries(formData.availability).map(([day, range]) => (
          <div key={day} className="flex items-center space-x-4">
            <span className="w-20 text-sm font-medium text-gray-900 dark:text-white">{day}</span>
            <input
              type="time"
              value={range.startTime}
              onChange={(e) => handleAvailabilityChange(day, 'startTime', e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
            <input
              type="time"
              value={range.endTime}
              onChange={(e) => handleAvailabilityChange(day, 'endTime', e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        ))}
      </div>

      {/* Pricing */}
      <div className="space-y-4">
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Subjects & Pricing</h3>
        <p>
          For the subjects toggle the cross if you&apos;re planning on teaching that subject.
        </p>
        {SubjectMapping.map((subject) => (
          <div key={subject.id} className="border border-gray-200 rounded-lg p-4 dark:border-gray-700">
            <h4
              className="text-lg font-medium text-gray-900 dark:text-white mb-2"
              style={{
                background: `linear-gradient(to right, ${subject.colors.from}, ${subject.colors.to})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              {subject.label}
            </h4>
            {DefaultLevels.map((level) => {
              const price = formData.pricing[subject.label]?.[level];
              const isTeaching = price !== undefined;
              const tooltipKey = `${subject.label}-${level}`;
              return (
                <div key={level} className="flex items-center space-x-2 mb-2">
                  <button
                    type="button"
                    onClick={() => toggleSubjectLevel(subject.label, level)}
                    className="p-1 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-300"
                  >
                    {isTeaching ? (
                      <Check className="w-5 h-5 text-green-500" />
                    ) : (
                      <X className="w-5 h-5 text-red-500" />
                    )}
                  </button>
                  <span className="w-40 text-sm font-medium text-gray-900 dark:text-white">{level}</span>
                  <div className="relative flex-1">
                    <input
                      type="number"
                      value={isTeaching ? price : ''}
                      onChange={(e) => handlePricingChange(subject.label, level, e.target.value ? Number(e.target.value) : '')}
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                        !isTeaching ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                      disabled={!isTeaching}
                      placeholder={isTeaching ? undefined : 'N/A'}
                      min={DefaultPricing[level]}
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-0 flex items-center pr-3"
                      onClick={() => toggleTooltip(subject.label, level)}
                    >
                      <Info className="w-5 h-5 text-gray-500" />
                    </button>
                    {showTooltip[tooltipKey] && (
                      <div className="absolute z-10 right-0 mt-2 p-2 bg-white border border-gray-200 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700">
                        <p className="text-sm text-gray-600 dark:text-gray-400">
                          Minimum price: £
                          {DefaultPricing[level]}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ))}
      </div>

      <button
        type="button"
        onClick={() => { onSave(formData); }}
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        Save Profile
      </button>
    </form>
  );
};

export default TutorProfileForm;
