import React, { useState, useRef, useEffect } from 'react';
import { Chat, Message } from '../../../types/collections/Chats';
import ChatBubble from './chat-bubbles/ChatBubble';
import { useAuth } from '../../../contexts/AuthContext';
import FirebaseDatabaseService from '../../../services/database/strategies/FirebaseFirestoreService';
import FirebaseStorageService from '../../../services/storage/strategies/FirebaseStorageService';
import SystemMessage from './chat-bubbles/SystemMessages';

interface ChatWindowProps {
  chat: Chat;
  newLesson: () => void;
}

const ChatWindow: React.FC<ChatWindowProps> = ({ chat, newLesson }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const { authState } = useAuth();
  const [newMessage, setNewMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const imageInputRef = useRef<HTMLInputElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    if (chat && chat.id) {
      unsubscribe = FirebaseDatabaseService.listenToQuery<Message>(
        'messages',
        'chatId',
        chat.id,
        'timestamp',
        (updatedMessages) => {
          if (updatedMessages) {
            setMessages(updatedMessages);
          }
        },
        (error) => {
          console.error('Error listening to messages:', error);
        },
        50, // Limit to 50 messages
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [chat]);

  const uploadFile = async (file: File, type: 'file' | 'image'): Promise<string> => {
    const path = `chat/${chat.id}/${type}_${Date.now()}_${file.name}`;
    try {
      const downloadURL = await FirebaseStorageService.uploadFile(path, file, (progress) => {
        console.log(`Upload is ${progress}% done`);
      });
      return downloadURL;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };

  const handleSendMessage = async () => {
    if (!authState.user) {
      console.error('User not authenticated');
      return;
    }

    setIsUploading(true);

    try {
      const message: Message = {
        id: '', // This will be set by Firebase
        text: newMessage,
        sender: authState.user.uid,
        timestamp: Date.now(),
        status: 'sent',
        chatId: chat.id,
      };

      if (selectedFile) {
        const fileUrl = await uploadFile(selectedFile, 'file');
        message.file = {
          fileName: selectedFile.name,
          fileSize: `${(selectedFile.size / 1024).toFixed(2)} KB`,
          fileType: selectedFile.type,
          fileUrl,
        };
      }

      if (selectedImage) {
        const imageUrl = await uploadFile(selectedImage, 'image');
        message.image = {
          imageUrl,
          caption: newMessage,
        };
      }

      FirebaseDatabaseService.addDocument(
        'messages',
        { ...message },
        (newMessageId) => {
          console.log('Message sent successfully with ID:', newMessageId);
          // No need to update local state, as the listener will handle it
          FirebaseDatabaseService.updateDocument(
            'chat',
            chat.id,
            {
              lastMessage: message.text,
              lastMessageTime: Date.now(),
            },
            () => {
              console.log('Successfully updated the chat');
            },
          );
        },
        (error) => {
          console.error('Error sending message:', error);
        },
      );

      setNewMessage('');
      setSelectedFile(null);
      setSelectedImage(null);
    } catch (error) {
      console.error('Error handling message send:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className="w-2/3 flex flex-col px-4">
      <div className="w-full border-b border-gray-200 py-2 flex items-center justify-between">
        <p className="font-bold dark:text-white text-2xl">
          {
              authState.user && chat.participant1Id === authState.user.uid
                ? chat.participant2Name
                : chat.participant1Name
            }
        </p>
        <div className="flex gap-2">
          <button
            type="button"
            className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            onClick={() => { newLesson(); }}
          >
            Schedule Lesson
          </button>
          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={() => { console.log('here'); }}
          >
            View Lessons
          </button>
        </div>
      </div>
      <div className="flex-1 overflow-y-auto mb-4 w-full">
        {messages.map((message) => {
          if (message.sender === 'system') {
            return <SystemMessage key={message.id} chat={chat} message={message} />;
          }
          return <ChatBubble key={message.id} chat={chat} message={message} />;
        })}
        <div ref={messagesEndRef} />
      </div>
      <div className="flex flex-col">
        <div className="flex items-center mb-2 gap-1">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            className="flex-1 p-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:text-white"
            placeholder="Type a message..."
          />
          <button
            type="button"
            onClick={() => fileInputRef.current?.click()}
            className="p-2"
            disabled={isUploading}
          >
            <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 8v8a5 5 0 1 0 10 0V6.5a3.5 3.5 0 1 0-7 0V15a2 2 0 0 0 4 0V8" />
            </svg>
          </button>
          <button
            type="button"
            onClick={() => imageInputRef.current?.click()}
            className="p-2"
            disabled={isUploading}
          >
            <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
              <path fillRule="evenodd" d="M13 10a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H14a1 1 0 0 1-1-1Z" clipRule="evenodd" />
              <path fillRule="evenodd" d="M2 6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12c0 .556-.227 1.06-.593 1.422A.999.999 0 0 1 20.5 20H4a2.002 2.002 0 0 1-2-2V6Zm6.892 12 3.833-5.356-3.99-4.322a1 1 0 0 0-1.549.097L4 12.879V6h16v9.95l-3.257-3.619a1 1 0 0 0-1.557.088L11.2 18H8.892Z" clipRule="evenodd" />
            </svg>
          </button>
          <button
            type="button"
            onClick={handleSendMessage}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            disabled={isUploading}
          >
            {isUploading ? 'Uploading...' : 'Send'}
          </button>
        </div>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          className="hidden"
        />
        <input
          type="file"
          ref={imageInputRef}
          onChange={handleImageChange}
          accept="image/*"
          className="hidden"
        />
        {selectedFile && (
          <div className="text-sm text-gray-600">
            File selected:
            {' '}
            {selectedFile.name}
          </div>
        )}
        {selectedImage && (
          <div className="text-sm text-gray-600">
            Image selected:
            {' '}
            {selectedImage.name}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatWindow;
