import React, { useState } from 'react';
import { TutorDocument } from '../../../../../types/collections/Tutor';

export interface ReferencesUploadProps {
  referencesUploaded: boolean,
  tutorDoc: TutorDocument,
  createOrUpdateTutorDocument: (values: Partial<TutorDocument>) => void
}

export const ReferencesUpload : React.FC<ReferencesUploadProps> = (
  { referencesUploaded, tutorDoc, createOrUpdateTutorDocument },
) => {
  const [references, setReferences] = useState({
    reference1: tutorDoc.reference1Email,
    reference2: tutorDoc.reference2Email,
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setReferences((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    createOrUpdateTutorDocument({
      reference1Email: references.reference1,
      reference2Email: references.reference2,
    });
  };

  return (
    <div hidden={referencesUploaded} id="accordion-flush-body-3" aria-labelledby="accordion-flush-heading-3">
      <div className="py-5 border-b border-gray-200 dark:border-gray-700">
        <p className="mb-2 text-gray-500 dark:text-gray-400">We also need two references from people you have previously worked with. Try to keep these references as relevant as possible.</p>
        <form onSubmit={handleSubmit}>
          <label
            htmlFor="email-address-icon"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Reference 1:
            {' '}
            {tutorDoc.reference1Email}
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                  <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                  <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
                </svg>
              </div>
              <input
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="name@tutr.ai"
                type="email"
                id="reference1"
                name="reference1"
                required
                value={references.reference1}
                onChange={handleInputChange}
              />
            </div>
          </label>

          <label
            htmlFor="email-address-icon"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Reference 2
            {' '}
            {tutorDoc.reference2Email}
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                  <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                  <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
                </svg>
              </div>
              <input
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="name@tutr.ai"
                type="email"
                id="reference2"
                name="reference2"
                required
                value={references.reference2}
                onChange={handleInputChange}
              />
            </div>
          </label>

          <button
            type="submit"
            className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          >
            Update References
          </button>

        </form>
      </div>
    </div>
  );
};
