import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Lesson } from '../../types/collections/Lesson'; // Import the Lesson type
import { Month, getMonthDetails, ScheduledEvent } from '../../types/Calendar';
import FirebaseFirestoreService from '../../services/database/strategies/FirebaseFirestoreService';
import { DayButton } from './Day';
import { UserDocument } from '../../types/collections/User';

interface SkeletonLessonProps {
}
const SkeletonLesson: React.FC<SkeletonLessonProps> = () => (
  <div role="status" className="animate-pulse p-5 border border-t border-gray-300 w-full rounded-xl">
    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4" />
    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5" />
    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5" />
    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5" />
    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5" />
    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]" />
    <span className="sr-only">Loading...</span>
  </div>
);

interface LessonCardProps {
  lesson: Lesson;
}

const LessonCard: React.FC<LessonCardProps> = ({ lesson }) => (
  <div className="p-5 border border-gray-300 w-full rounded-xl bg-white dark:bg-gray-800">
    <h3 className="text-xl font-bold text-gray-900 dark:text-white">{lesson.subject}</h3>
    <p className="text-gray-600 dark:text-gray-400">{lesson.level}</p>
    <p className="text-gray-600 dark:text-gray-400">
      Date:
      {lesson.startDate}
    </p>
    <p className="text-gray-600 dark:text-gray-400">
      Time:
      {lesson.time}
    </p>
    <p className="text-gray-600 dark:text-gray-400">
      Cost: $
      {lesson.cost}
    </p>
    {lesson.zoomLink && (
      <a
        href={lesson.zoomLink}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-600 dark:text-blue-400"
      >
        Join Zoom Meeting
      </a>
    )}
  </div>
);

export const LessonsList: React.FC = () => {
  const { authState } = useAuth();
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchLessons = async () => {
      if (authState.user?.uid) {
        FirebaseFirestoreService.queryDocuments(
          'lessons',
          'tutorId', // Assuming 'participants' is an array containing both tutorId and studentId
          authState.user?.uid,
          'startDate', // Assuming lessons are ordered by their start date
          20, // Fetch a maximum of 20 lessons at a time
          undefined, // Not using pagination here
          (documents) => {
            setLessons(documents.map((elem) => elem as Lesson));
            setLoading(false);
          },
          (error) => {
            console.error('Error fetching lessons:', error);
            setLoading(false);
          },
        );
      }
    };

    fetchLessons();
  }, [authState.user]);

  if (loading) {
    return <SkeletonLesson />; // Display the skeleton loader while loading
  }

  if (lessons.length === 0) {
    return (
      <div className="w-full min-h-[350px] flex flex-col gap-2 items-center justify-center">
        <p className="text-gray-700 dark:text-gray-400">No lessons scheduled...</p>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col gap-5">
      {lessons.map((lesson) => (
        <LessonCard key={lesson.startDate + lesson.time} lesson={lesson} />
      ))}
    </div>
  );
};

export interface CalendarViewProps {
  currentMonth: Month;
  changeMonth: (offset: number) => void;
  scheduledEvents: ScheduledEvent[];
}

export const CalendarView: React.FC<CalendarViewProps> = (
  { currentMonth, changeMonth, scheduledEvents },
) => {
  const [offSet, setOffset] = useState(0);
  const getScheduledEvent = (date: Date) => scheduledEvents
    .find((event) => event.date.getDate() === date.getDate()
      && event.date.getMonth() === date.getMonth()
      && event.date.getFullYear() === date.getFullYear());

  return (
    <div className="max-w-[350px] w-full flex flex-col gap-5 justify-start items-center">
      <div className="flex gap-2 items-center">
        <div className="inline-flex rounded-md shadow-sm" role="group">
          <button
            type="button"
            onClick={() => {
              changeMonth(offSet - 1);
              setOffset((prevState) => prevState - 1);
            }}
            className="px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-s-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"
          >
            Left
          </button>
          <button type="button" className="px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border-t border-b border-gray-900 hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700">
            {currentMonth.name}
          </button>
          <button
            type="button"
            onClick={() => {
              changeMonth(offSet + 1);
              setOffset((prevState) => prevState + 1);
            }}
            className="px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-e-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"
          >
            Next
          </button>
        </div>
      </div>
      <div className="flex gap-2 flex-wrap w-full">
        {currentMonth.days.map((day) => (
          <DayButton
            key={day.date.toString()}
            day={day}
            scheduledEvent={getScheduledEvent(day.date)}
          />
        ))}
      </div>
    </div>
  );
};

export interface CalendarProps {
  userDoc: UserDocument;
}

export const Calendar: React.FC<CalendarProps> = ({ userDoc }) => {
  const [currentMonth, setCurrentMonth] = useState<Month>(() => getMonthDetails(new Date()));

  const changeMonth = (offset: number) => {
    const current = new Date(
      currentMonth.days[0].date.getFullYear(),
      currentMonth.days[0].date.getMonth() + offset,
    );
    setCurrentMonth(getMonthDetails(current));
  };

  return (
    <div className="w-full flex flex-wrap gap-5 justify-between">
      {/* Calendar View */}
      <CalendarView currentMonth={currentMonth} changeMonth={changeMonth} scheduledEvents={[]} />

      {/* Lessons Scheduled */}
      <div className="min-w-[350px] flex-1 flex flex-col justify-center items-center">
        {/* Heading */}
        <div className="flex justify-between items-center w-full">
          <p className="mb-4 text-4xl font-bold text-gray-900 dark:text-warning">
            Lessons Scheduled
          </p>
          {userDoc.accountType === 'student' && (
          <a
            href="/new-lesson"
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            New Lesson
          </a>
          )}
        </div>

        {/* List of Lessons */}
        <LessonsList />
      </div>
    </div>
  );
};
