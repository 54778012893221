import React, { useEffect, useState } from 'react';
import Jdenticon from 'react-jdenticon';
import { Chat, LessonRequest, Message } from '../../../../types/collections/Chats';
import { getMonthDetails, ScheduledEvent } from '../../../../types/Calendar';
import { CalendarView } from '../../../calendar/Calendar';
import { useAuth } from '../../../../contexts/AuthContext';
import FirebaseFirestoreService from '../../../../services/database/strategies/FirebaseFirestoreService';
import { UserDocument } from '../../../../types/collections/User';
import StepperIndicator from '../../../stepper/StepperIndicator';
import StripePaymentService from '../../../../services/payments/strategies/StripePaymentService';

interface SystemMessageProps {
  chat: Chat;
  message: Message;
}

const parseDateString = (dateString: string): Date => {
  const [year, month, day] = dateString.split('-').map(Number);
  return new Date(year, month - 1, day);
};

const SystemMessage: React.FC<SystemMessageProps> = ({ chat, message }) => {
  const startDate = message.lessonRequest?.startDate
    ? parseDateString(message.lessonRequest?.startDate)
    : new Date();
  const [currentDate, setCurrentDate] = useState(
    message.lessonRequest?.startDate
      ? parseDateString(message.lessonRequest?.startDate)
      : new Date(),
  );
  const { authState } = useAuth();
  // const [tutorDoc, setTutorProfile] = useState<TutorProfileType | undefined>(undefined);
  const [studentUserDoc, setStudentUserDoc] = useState<UserDocument| undefined>(undefined);
  // const [tutorUserDoc, setTutorUserDoc] = useState<UserDocument| undefined>(undefined);
  const [isStudent, setIsStudent] = useState(false);
  const [isParticipant1, setIsParticipant1] = useState(false);

  useEffect(() => {
    console.log(isStudent);
    console.log(isParticipant1);
  }, [isStudent, isParticipant1]);

  useEffect(() => {
    FirebaseFirestoreService.queryDocuments(
      'users',
      'uid',
      chat.participant1Id,
      'fullName',
      1,
      undefined,
      (docs) => {
        if (docs.length > 0) {
          if (isParticipant1 && isStudent) {
            setStudentUserDoc(docs[0] as UserDocument);
          }
        }
      },
      (error) => {
        console.log(error);
      },
    );
    FirebaseFirestoreService.queryDocuments(
      'users',
      'uid',
      chat.participant2Id,
      'fullName',
      1,
      undefined,
      (docs) => {
        if (docs.length > 0) {
          if (!isParticipant1 && isStudent) {
            setStudentUserDoc(docs[0] as UserDocument);
          }
        }
      },
      (error) => {
        console.log(error);
      },
    );
  }, [chat, isStudent, isParticipant1]);

  const changeMonth = (offset: number) => {
    setCurrentDate(new Date(startDate.getFullYear(), startDate.getMonth() + offset, 1));
  };

  const generateDatesForLesson = (lesson: LessonRequest): Date[] => {
    const dates: Date[] = [];

    const firstDate = startDate;
    for (let i = 0; i < lesson.numberOfLessons; i += 1) {
      if (lesson.recurrence.frequency === 'once') {
        dates.push(firstDate);
      } else if (lesson.recurrence.frequency === 'daily') {
        firstDate.setDate(startDate.getDate() + i);
        dates.push(firstDate);
      } else if (lesson.recurrence.frequency === 'weekly') {
        firstDate.setDate(startDate.getDate() + i * 7);
        if (lesson.recurrence.daysOfWeek.includes(currentDate.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase())) {
          dates.push(firstDate);
        }
      } else if (lesson.recurrence.frequency === 'monthly') {
        firstDate.setMonth(startDate.getMonth() + i * lesson.recurrence.interval);
        dates.push(firstDate);
      }
    }
    console.log(dates);
    return dates;
  };

  const takeDatesAndReturnScheduledEvents = (dates: Date[]) : ScheduledEvent[] => (
    dates.map((elem) => ({
      date: elem,
      component:
  <ul>
    <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">{elem.toString()}</li>
    <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Set unavailable</li>
    <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">View events</li>
  </ul>,
    } as ScheduledEvent))
  );

  useEffect(() => {
    if (authState.user) {
      const userId = authState.user.uid;
      FirebaseFirestoreService.getDocument(
        'tutor-profile',
        userId,
        (doc) => {
          if (doc) {
            // setTutorProfile(doc as TutorProfileType);
            setIsStudent(false);
          } else {
            setIsParticipant1(userId === chat.participant1Id);
            setIsStudent(true);
          }
        },
        () => console.log('Failed to fetch tutor profile'),
      );
    }
  }, [authState, chat.participant1Id, message]);

  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [paymentError, setPaymentError] = useState<string | null>(null);

  const handlePayment = async () => {
    if (!message.lessonRequest || !studentUserDoc?.stripeCustomerId) {
      setPaymentError('Unable to process payment. Please contact support.');
      return;
    }

    setIsProcessingPayment(true);
    setPaymentError(null);

    try {
      const amount = message.lessonRequest.cost * message.lessonRequest.numberOfLessons * 100;
      const currency = 'gbp';

      // Create a Checkout Session
      await StripePaymentService.createCheckoutSession(
        amount,
        currency,
        studentUserDoc.stripeCustomerId,
        `${window.location.origin}/payment-success?message_id=${message.id}`, // Success URL
        `${window.location.origin}/payment-cancel?message_id=${message.id}`, // Cancel URL
        (sessionUrl) => {
          // Redirect to Stripe Checkout
          window.location.href = sessionUrl;
        },
        (error) => {
          console.error('Error creating checkout session:', error);
          setPaymentError('Failed to initiate payment. Please try again.');
          setIsProcessingPayment(false);
        },
      );
    } catch (error) {
      console.error('Error in handlePayment:', error);
      setPaymentError('An unexpected error occurred. Please try again.');
      setIsProcessingPayment(false);
    }
  };

  const handleConfirmLesson = () => {
    FirebaseFirestoreService.updateDocument(
      'messages',
      message.id,
      {
        lessonStatus: 'confirmed',
      },
      () => {
        console.log('Lesson Confirmed');
      },
    );
  };

  const getTutorName = () => {
    if (isStudent) {
      if (isParticipant1) {
        return chat.participant2Name;
      }
      return chat.participant1Name;
    }
    if (isParticipant1) {
      return chat.participant1Name;
    }
    return chat.participant2Name;
  };

  const getStudentName = () => {
    if (!isStudent) {
      if (isParticipant1) {
        return chat.participant2Name;
      }
      return chat.participant1Name;
    }
    if (isParticipant1) {
      return chat.participant1Name;
    }
    return chat.participant2Name;
  };

  const renderMessageContent = () => {
    if (message.lessonRequest) {
      return (
        <div className="flex gap-4 justify-between w-[90%] my-2 flex-wrap lg:flex-nowrap">
          <div className="flex flex-col w-full lg:w-[50%]">
            <div className="w-[90%] bg-primary rounded-xl text-white p-4 px-6 m-2">
              <p className="font-bold text-lg">
                {message.lessonRequest.subject}
                {', '}
                {message.lessonRequest.level}
              </p>
              <span className="text-md my-2 font-normal text-gray-100">
                <span className="font-bold">Details: </span>
                {
                  message.lessonRequest.lessonDetails
                  && message.lessonRequest.lessonDetails.substring(0, 200)
                }
              </span>
              <div className="w-full flex justify-end">
                <p className="font-bold text-md text-gray-400">Lesson Proposal</p>
              </div>
            </div>
            <div className="h-[1px] rounded-full w-full bg-gray-300" />

            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Description
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Quantity
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Cost
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-t dark:border-gray-700">
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      1Hr Tutoring Session w/
                      {' '}
                      {getTutorName()}
                    </th>
                    <td className="px-6 py-4">
                      {message.lessonRequest.numberOfLessons}
                    </td>
                    <td className="px-6 py-4">
                      {message.lessonRequest.cost}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex gap-2 items-center">
                <Jdenticon size="20" value={getTutorName()} />
                <button
                  disabled={isStudent || (message.lessonStatus && message.lessonStatus === 'confirmed')}
                  type="button"
                  className="flex gap-2 text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800 disabled:bg-gray-400 disabled:text-gray-200 disabled:cursor-not-allowed disabled:border-none disabled:hover:bg-gray-400"
                  onClick={handleConfirmLesson}
                >
                  Confirm Lesson Time
                </button>
              </div>
              <div className="flex gap-2 items-center">
                <Jdenticon size="20" value={getStudentName()} />
                <button
                  disabled={!isStudent || isProcessingPayment || !studentUserDoc?.stripeCustomerId || (message.lessonStatus && message.lessonStatus === 'pending')}
                  onClick={handlePayment}
                  // || message.status === 'paid'
                  type="button"
                  className="flex gap-2 text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800 disabled:bg-gray-400 disabled:text-gray-200 disabled:cursor-not-allowed disabled:border-none disabled:hover:bg-gray-400"
                >
                  {isProcessingPayment ? 'Processing...' : 'Pay for Lessons'}
                </button>
              </div>
              {
                isStudent && (message.lessonStatus && message.lessonStatus === 'pending')
                && <p className="text-red-500 text-sm">You have to wait for the tutor to confirm your lesson before you can pay.</p>
              }
              {paymentError && (
                <p className="text-red-500 text-sm mt-2">{paymentError}</p>
              )}
              {!studentUserDoc?.stripeCustomerId && isStudent && (
                <p className="text-yellow-500 text-sm mt-2">Please set up your payment method before proceeding.</p>
              )}
              <button
                type="button"
                className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                onClick={() => {
                  FirebaseFirestoreService.deleteDocument(
                    'messages',
                    message.id,
                    () => {
                      console.log(message.id);
                      console.log('successfully deleted message');
                    },
                    () => {
                      console.log('Unsuccessfully deleted message');
                    },
                  );
                }}
              >
                Cancel Lesson
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <CalendarView
              currentMonth={getMonthDetails(currentDate)}
              changeMonth={changeMonth}
              scheduledEvents={
                takeDatesAndReturnScheduledEvents(generateDatesForLesson(message.lessonRequest))
              }
            />
            <span className="text-md my-2 font-normal text-gray-900 dark:text-white">
              <span className="font-bold">Start Date:</span>
              {new Date(message.lessonRequest.startDate).toLocaleDateString()}
              {': '}
              {message.lessonRequest.time}
            </span>
            <StepperIndicator steps={['Tutor Confirmed Time', 'Student Payment', 'Lesson Complete']} currentStep={0} />
          </div>
        </div>
      );
    }
    return undefined;
  };

  return (
    <div className="flex items-start gap-2.5 my-2">
      <div className="flex flex-col items-center w-full max-w-[90%] m-auto leading-1.5 p-4 border-gray-200 bg-gray-100 rounded-xl dark:bg-gray-700">
        <div className="flex items-center space-x-2 rtl:space-x-reverse flex-col gap-2 my-2">
          <span className="text-sm font-semibold text-gray-900 dark:text-white w-full text-center">System Message</span>
        </div>
        {renderMessageContent()}
        <span className="text-sm font-normal text-gray-500 dark:text-gray-400">{message.status}</span>
      </div>
      {/* Dropdown menu button */}
    </div>
  );
};

export default SystemMessage;
