import React from 'react';
import { LessonProposal } from '../../../types/collections/LessonProposal';

export interface TutorPreferencesProps {
  previousStep: () => void,
  nextStep: () => void,
  lessonPlan: LessonProposal,
  setLessonPlan: React.Dispatch<React.SetStateAction<LessonProposal>>
}

export const TutorPreferences: React.FC<TutorPreferencesProps> = ({
  previousStep, nextStep, lessonPlan, setLessonPlan,
}) => (
  <div
    className="max-w-screen-lg w-full flex flex-col justify-center items-start p-4  text-gray-500 dark:text-gray-400 gap-2"
  >
    <h2 className="my-4 text-4xl font-bold text-gray-900 dark:text-warning">
      Tutor Preferences
    </h2>
    <p>
      Let us know if you have any preferences for your tutor. We will try and use this to
      match you to a tutor who fits your description.
    </p>
    <form className="mx-auto w-full flex flex-col gap-2">
      {/* Gender Preferences */}
      <p className="text-gray-900 dark:text-white font-bold text-xl">Gender Preferences</p>
      <div className="flex gap-2">
        <button
          disabled={lessonPlan.genderPreference === 'Male'}
          type="button"
          className="px-4 py-2 text-sm font-medium rounded text-gray-900 bg-transparent border border-gray-900 hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700 disabled:bg-gray-700 disabled:text-white disabled:dark:bg-gray-200 disabled:dark:text-gray-900"
          onClick={() => {
            setLessonPlan((prevState) => ({
              ...prevState,
              genderPreference: 'Male',
            }));
          }}
        >
          Male
        </button>
        <button
          disabled={lessonPlan.genderPreference === 'Female'}
          type="button"
          className="px-4 py-2 text-sm font-medium rounded text-gray-900 bg-transparent border border-gray-900 hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700 disabled:bg-gray-700 disabled:text-white disabled:dark:bg-gray-200 disabled:dark:text-gray-900"
          onClick={() => {
            setLessonPlan((prevState) => ({
              ...prevState,
              genderPreference: 'Female',
            }));
          }}
        >
          Female
        </button>
        <button
          disabled={lessonPlan.genderPreference === 'No Preference'}
          type="button"
          className="px-4 py-2 text-sm font-medium rounded text-gray-900 bg-transparent border border-gray-900 hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700 disabled:bg-gray-700 disabled:text-white disabled:dark:bg-gray-200 disabled:dark:text-gray-900"
          onClick={() => {
            setLessonPlan((prevState) => ({
              ...prevState,
              genderPreference: 'No Preference',
            }));
          }}
        >
          No Preference
        </button>
      </div>
      {/* Additional Information */}
      <p className="text-gray-900 dark:text-white font-bold text-xl">Additional Requirements</p>
      <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        Write some information for your tutor.
        <textarea
          onChange={(event) => {
            setLessonPlan((prevState) => (
              { ...prevState, tutorPreferenceNotes: event.target.value }
            ));
          }}
          id="message"
          rows={4}
          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Write your thoughts here..."
          value={lessonPlan.tutorPreferenceNotes}
        />
      </label>

    </form>

    <div className="w-full flex justify-between items-center">
      <button
        type="button"
        onClick={() => { previousStep(); }}
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
      >
        Back
      </button>
      <button
        type="button"
        onClick={() => { nextStep(); }}
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
      >
        Next
      </button>
    </div>
  </div>
);
