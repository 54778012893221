import React from 'react';
import { LessonProposal } from '../../../types/collections/LessonProposal';
import { TutorProfileType } from '../../../types/TutorProfile';
import { TutorProfileCard } from '../../tutor-profile-card/TutorProfileCard';
import { UserDocument } from '../../../types/collections/User';

export interface ReviewLessonProps {
  previousStep: () => void,
  nextStep: () => void,
  lessonPlan: LessonProposal,
  selectedTutor: TutorProfileType | undefined,
  selectedTutorUserDoc: UserDocument | undefined,
}

export const ReviewLesson: React.FC<ReviewLessonProps> = ({
  previousStep, nextStep, lessonPlan, selectedTutor, selectedTutorUserDoc,
}) => (
  <div
    className="max-w-screen-lg w-full flex flex-col justify-center items-start p-4  text-gray-500 dark:text-gray-400 gap-2"
  >
    <h2 className="my-4 text-4xl font-bold text-gray-900 dark:text-warning">
      Review
    </h2>
    <p>
      Review your application before it gets submitted!
    </p>

    <p>{lessonPlan.lessonTimeNotes}</p>
    {
      selectedTutor && selectedTutorUserDoc
        ? (
          <TutorProfileCard
            tutorProfileType={selectedTutor}
            userDoc={selectedTutorUserDoc}
            pickTutor={() => {}}
          />
        )
        : <p className="text-red-500">No tutor selected... Go back and pick someone.</p>
    }

    <div className="w-full flex justify-between items-center">
      <button
        type="button"
        onClick={() => { previousStep(); }}
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
      >
        Back
      </button>
      <button
        type="button"
        disabled={!selectedTutor}
        onClick={() => { nextStep(); }}
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 disabled:bg-gray-300 disabled:cursor-not-allowed disabled:dark:bg-gray-600"
      >
        Next
      </button>
    </div>
  </div>
);
