export type LessonDay = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';
export interface LessonTime {
  day: LessonDay[],
  time: string,
}

export interface LessonProposal {
  subject: string,
  level: string,
  genderPreference: string,
  teachingFormat: string,
  postcode: string,
  tutorEducationLevel: string,
  tutorPreferenceNotes: string,
  lessonRepeating: string,
  lessonTimes: LessonTime[],
  lessonTimeNotes: string,
}

export const emptyLessonProposal :LessonProposal = {
  subject: '',
  level: '',
  genderPreference: '',
  teachingFormat: '',
  postcode: '',
  tutorEducationLevel: '',
  tutorPreferenceNotes: '',
  lessonRepeating: '',
  lessonTimes: [],
  lessonTimeNotes: '',
};
