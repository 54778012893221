import React, { useCallback, useEffect, useState } from 'react';
import {
  CheckCircle2, AlertCircle, Clock, Download, Upload,
} from 'lucide-react';
import { UserDocument } from '../../../../types/collections/User';
import FirebaseFirestoreService from '../../../../services/database/strategies/FirebaseFirestoreService';
import FirebaseStorageService from '../../../../services/storage/strategies/FirebaseStorageService';
import { DocumentStatus, StudentDocumentsStatus } from '../../../../types/collections/StudentDocumentsStatus';

export interface StudentDocumentChecksProps {
  userDoc: UserDocument;
}

export const StudentDocumentChecks: React.FC<StudentDocumentChecksProps> = ({ userDoc }) => {
  const [
    studentDocumentChecks,
    setStudentDocumentChecks,
  ] = useState<StudentDocumentsStatus | undefined>(undefined);
  const [uploadProgress, setUploadProgress] = useState<{ [key: string]: number }>({});

  const createInitialDocumentStatus = useCallback((docLocation: string): DocumentStatus => ({
    documentUrl: docLocation,
    read: false,
    readTimestamp: '',
    signedDocumentUrl: '',
    signedTimestamp: '',
    signed: false,
    counterSignedDocumentUrl: '',
    counterSignedTimestamp: '',
    counterSigned: false,
  }), []);

  const createInitialStudentDocumentStatus = useCallback((): StudentDocumentsStatus => ({
    uid: userDoc.uid,
    clientPrivacyPolicy: createInitialDocumentStatus('/documents/Tütr AI - CLIENT Privacy Policy.pdf'),
    studentPrivacyPolicy: createInitialDocumentStatus('/documents/Tütr AI - STUDENT Privacy Policy.pdf'),
    safeGuardingPolicy: createInitialDocumentStatus('/documents/Tütr AI - Safeguarding Policy.pdf'),
    reschedulingPolicy: createInitialDocumentStatus('/documents/Tütr AI - Rescheduling and Attendance Policy.pdf'),
    workingTogether: createInitialDocumentStatus('/documents/Tütr AI - Working Together Policy.pdf'),
    clientTerms: createInitialDocumentStatus('/documents/Tütr AI - Client Terms.pdf'),
  }), [userDoc.uid, createInitialDocumentStatus]);

  const initializeDocument = useCallback(async (): Promise<StudentDocumentsStatus | null> => {
    try {
      return new Promise((resolve) => {
        FirebaseFirestoreService.getDocument<StudentDocumentsStatus>(
          'student-document-status',
          userDoc.uid,
          (document) => {
            if (document) {
              resolve(document);
            } else {
              const initialDocStatus = createInitialStudentDocumentStatus();
              FirebaseFirestoreService.setDocument(
                'student-document-status',
                userDoc.uid,
                initialDocStatus,
                () => {
                  console.log('Document Created');
                  resolve(initialDocStatus);
                },
                (error) => {
                  console.log(error.message);
                  resolve(null);
                },
              );
            }
          },
          (error) => {
            console.log(error.message);
            resolve(null);
          },
        );
      });
    } catch (error) {
      console.error('Error initializing document:', error);
      return null;
    }
  }, [userDoc.uid, createInitialStudentDocumentStatus]);

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    const setupListener = async () => {
      if (userDoc.uid) {
        // First, ensure the document exists
        const initialDoc = await initializeDocument();
        if (initialDoc) {
          setStudentDocumentChecks(initialDoc);
        }

        // Then set up the listener
        unsubscribe = FirebaseFirestoreService.listenToDocument<StudentDocumentsStatus>(
          'student-document-status',
          userDoc.uid,
          (doc) => {
            console.log('Document updated:', doc);
            if (doc) {
              setStudentDocumentChecks(doc);
            }
          },
          (error) => {
            console.error('Error listening to document:', error);
          },
        );
      }
    };

    setupListener();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [userDoc.uid, initializeDocument]);

  type DocumentKeys = keyof Omit<StudentDocumentsStatus, 'id' | 'uid'>;

  const documents: { key: DocumentKeys; title: string }[] = [
    { key: 'clientPrivacyPolicy', title: 'Client Privacy Policy' },
    { key: 'studentPrivacyPolicy', title: 'Student Privacy Policy' },
    { key: 'safeGuardingPolicy', title: 'Safeguarding Policy' },
    { key: 'reschedulingPolicy', title: 'Rescheduling Policy' },
    { key: 'workingTogether', title: 'Working Together' },
    { key: 'clientTerms', title: 'Client Terms' },
  ];
  const updateDocumentStatus = (key: DocumentKeys, update: Partial<DocumentStatus>) => {
    if (studentDocumentChecks) {
      const updatedStatus = { ...studentDocumentChecks[key], ...update };
      FirebaseFirestoreService.updateDocument(
        'student-document-status',
        userDoc.uid,
        { [key]: updatedStatus },
        () => console.log('Document status updated successfully'),
        (error) => console.log('Error updating document status:', error),
      );
    }
  };

  const handleDownload = async (key: DocumentKeys) => {
    if (studentDocumentChecks && studentDocumentChecks[key]) {
      const link = await FirebaseStorageService.getDownloadURL(
        studentDocumentChecks[key].documentUrl,
      );
      window.open(link, '_blank');
      updateDocumentStatus(key, {
        read: true,
        readTimestamp: new Date().toISOString(),
      });
    }
  };

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>, key: DocumentKeys) => {
    const file = event.target.files?.[0];
    if (file && studentDocumentChecks) {
      try {
        const path = `documents/${userDoc.uid}/${key}_signed`;
        const signedDocumentUrl = await FirebaseStorageService.uploadFile(
          path,
          file,
          (progress) => setUploadProgress((prev) => ({ ...prev, [key]: progress })),
        );
        updateDocumentStatus(key, {
          signedDocumentUrl,
          signed: true,
          signedTimestamp: new Date().toISOString(),
        });
      } catch (error) {
        console.error(`Error uploading file for ${key}:`, error);
      }
    }
  };

  const agreeToDocument = async (key: DocumentKeys) => {
    if (studentDocumentChecks) {
      try {
        updateDocumentStatus(key, {
          signed: true,
          signedTimestamp: new Date().toISOString(),
        });
      } catch (error) {
        console.error(`Error uploading file for ${key}:`, error);
      }
    }
  };

  const getStatusIcon = (status: boolean) => (status ? <CheckCircle2 className="text-green-500" /> : <Clock className="text-yellow-500" />);

  const [completed, setCompleted] = useState(false);
  useEffect(() => {
    if (studentDocumentChecks) {
      setCompleted(
        studentDocumentChecks.studentPrivacyPolicy.counterSigned
          && studentDocumentChecks.clientPrivacyPolicy.counterSigned
          && studentDocumentChecks.reschedulingPolicy.counterSigned
          && studentDocumentChecks.clientTerms.counterSigned
          && studentDocumentChecks.safeGuardingPolicy.counterSigned
          && studentDocumentChecks.workingTogether.counterSigned,
      );
    }
  }, [studentDocumentChecks]);

  if (completed) {
    return <div />;
  }
  return (
    <div className="w-full mx-auto">
      <h2 className="text-2xl font-bold mb-4">Document Checks</h2>
      <p className="mb-2">Before you can begin getting lessons, you need to complete the following steps.</p>
      {studentDocumentChecks ? (
        <div className="flex overflow-x-scroll space-x-4 p-4 w-full bg-gray-200 rounded-xl">
          {documents.map((doc) => {
            const documentStatus = studentDocumentChecks[doc.key];
            return (
              <div key={doc.key} className="min-x-[200px] max-w-md p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                <h5 className="text-lg font-bold tracking-tight text-gray-900 dark:text-white">
                  {doc.title}
                </h5>
                <div className="space-y-2 mt-2">
                  <div className="flex justify-between items-center">
                    <span>Read:</span>
                    {getStatusIcon(documentStatus.read)}
                  </div>
                  <div className="flex justify-between items-center">
                    <span>Signed:</span>
                    {getStatusIcon(documentStatus.signed)}
                  </div>
                  <div className="flex justify-between items-center">
                    <span>Counter Signed:</span>
                    {getStatusIcon(documentStatus.counterSigned)}
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => handleDownload(doc.key)}
                  className="mt-2 flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  <Download className="mr-2" size={16} />
                  Document
                </button>

                {documentStatus.read && !documentStatus.signed && doc.key === 'clientTerms' && (
                  <div className="mt-2">
                    <label htmlFor={`upload-${doc.key}`} className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800 cursor-pointer">
                      <Upload className="mr-2" size={16} />
                      Upload Signed Document
                    </label>
                    <input
                      id={`upload-${doc.key}`}
                      type="file"
                      onChange={(e) => handleUpload(e, doc.key)}
                      className="hidden"
                    />
                  </div>
                )}
                {documentStatus.read && !documentStatus.signed && doc.key !== 'clientTerms' && (
                  <div className="mt-2">
                    <button
                      type="button"
                      onClick={() => {
                        agreeToDocument(doc.key);
                      }}
                      className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800 cursor-pointer"
                    >
                      Accept Terms
                    </button>
                  </div>
                )}
                {uploadProgress[doc.key] !== undefined && uploadProgress[doc.key] < 100 && (
                  <div className="mt-2">
                    <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                      <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${uploadProgress[doc.key]}%` }} />
                    </div>
                    <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                      Upload progress:
                      {uploadProgress[doc.key].toFixed(0)}
                      %
                    </p>
                  </div>
                )}
                {documentStatus.signed && !documentStatus.counterSigned && (
                  <p className="mt-2 text-sm text-yellow-500">Waiting for counter-signature</p>
                )}
                {documentStatus.counterSigned && (
                  <p className="mt-2 text-sm text-green-500">Document fully signed</p>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="text-center">
          <AlertCircle className="mx-auto text-yellow-500" size={48} />
          <p className="mt-2">Loading document status...</p>
        </div>
      )}
    </div>
  );
};
