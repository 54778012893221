import React from 'react';
import { TutorDocument } from '../../../../../types/collections/Tutor';

export interface EmploymentStatusProps {
  employmentComplete: boolean,
  tutorDoc: TutorDocument,
  createOrUpdateTutorDocument: (values: Partial<TutorDocument>) => void
}

export const EmploymentStatus: React.FC<EmploymentStatusProps> = (
  { employmentComplete, tutorDoc, createOrUpdateTutorDocument },
) => (
  <div hidden={employmentComplete} id="accordion-flush-body-3" aria-labelledby="accordion-flush-heading-3">
    <div className="py-5 border-b border-gray-200 dark:border-gray-700">
      <span className="mb-2 text-gray-500 dark:text-gray-400">
        Working with Tutr.ai you will be working as a
        self-employed individual.
      </span>
      <br />
      <button
        type="button"
        disabled={tutorDoc.employmentStatusAgreementDate !== undefined}
        className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 disabled:dark:bg-green-400 disabled:bg-green-600"
        onClick={() => {
          createOrUpdateTutorDocument({ employmentStatusAgreementDate: new Date() });
        }}
      >
        {tutorDoc.employmentStatusAgreementDate === undefined ? 'Agree' : 'Agreed'}
      </button>

    </div>
  </div>
);
