import React from 'react';

export interface NotFoundPageProps {

}

export const NotFoundPage: React.FC<NotFoundPageProps> = () => (
  <div className="w-full h-screen flex flex-col justify-center items-center relative gap-2 dark:bg-gray-900">
    <p className="absolute top-1/2 left-1/2 text-9xl -translate-x-1/2 -translate-y-full text-blue-500/20 font-bold dark:text-blue-50/20">404</p>
    <p className="text-4xl font-bold dark:text-white">Feeling lost?</p>
    <span className="dark:text-white">That&apos;s because you are.</span>
    <span className="dark:text-white">
      Make your way back
      <a className="font-bold text-primary underline" href="/"> home </a>
    </span>
  </div>
);
