import React, { ReactNode } from 'react';

export interface StickyBannerProps {
  children: ReactNode;
  isHidden: boolean;
  setHidden: React.Dispatch<React.SetStateAction<boolean>>;
}

export const StickyBanner: React.FC<StickyBannerProps> = ({ children, isHidden, setHidden }) => {
  if (isHidden) {
    return null;
  }

  return (
    <div className="sticky top-0 start-0 z-20 flex justify-between w-full p-4 border-b border-gray-200 bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
      <div className="flex items-center mx-auto">
        {children}
      </div>
      <div className="flex items-center">
        <button
          onClick={() => setHidden(true)}
          type="button"
          className="flex-shrink-0 inline-flex justify-center w-7 h-7 items-center text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">Close banner</span>
        </button>
      </div>
    </div>
  );
};
