import {
  getStorage, ref, uploadBytesResumable, getDownloadURL,
  getBlob, deleteObject,
} from 'firebase/storage';
import app from '../../../config/firebaseConfig';
import { StorageService } from '../StorageInterface';

const storage = getStorage(app);

const FirebaseStorageService: StorageService = {
  async uploadFile(
    path: string,
    file: File,
    onProgress?: (progress: number) => void,
  ): Promise<string> {
    console.log(path);
    const storageRef = ref(storage, path);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if (onProgress) onProgress(progress);
        },
        (error) => {
          reject(error);
        },
        () => {
          // Handle successful uploads on complete
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
            return downloadURL;
          });
        },
      );
    });
  },

  async downloadFile(path: string): Promise<Blob> {
    const storageRef = ref(storage, path);
    return getBlob(storageRef);
  },

  async getDownloadURL(path: string): Promise<string> {
    if (!path) {
      throw new Error('Path cannot be empty');
    }

    const storageRef = ref(storage, path);
    return getDownloadURL(storageRef)
      .then((downloadURL) => {
        console.log('URL retrieved:', downloadURL);
        return downloadURL;
      })
      .catch((error) => {
        console.error('Error fetching download URL:', error);
        throw error;
      });
  },

  async deleteFile(path: string): Promise<void> {
    const storageRef = ref(storage, path);
    await deleteObject(storageRef);
  },

};

export default FirebaseStorageService;
