import React from 'react';
import { UserDocument } from '../../../../types/collections/User';
import { SubjectMapping } from '../../../../types/Subjects';

export interface SubjectsProps {
  userDocument: UserDocument,
  setUserDocument: React.Dispatch<React.SetStateAction<UserDocument>>,
}

export const Subjects: React.FC<SubjectsProps> = ({ userDocument, setUserDocument }) => {
  // Function to toggle a subject's selection
  const toggleSubject = (id: string) => {
    setUserDocument((prevUserDocument) => (prevUserDocument.subjects.includes(id)
      ? {
        ...prevUserDocument,
        subjects: prevUserDocument.subjects.filter((subjectId) => subjectId !== id),
      }
      : {
        ...prevUserDocument,
        subjects: [...prevUserDocument.subjects, id],
      }

    ));
  };

  return (
    <div className="mx-auto max-w-screen-sm text-center">
      <h2 className="mb-4 text-4xl font-extrabold leading-tight text-gray-900 dark:text-white">Select your subjects</h2>
      <p className="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">Select the subjects you are interested in.</p>
      <div className="w-full flex justify-center gap-2 flex-wrap max-w-[80%] m-auto">
        {SubjectMapping.map((subject) => (
          <button
            type="button"
            key={subject.id}
            onClick={() => toggleSubject(subject.id)}
            style={{
              backgroundImage: `linear-gradient(to bottom right, ${subject.colors.from}, ${subject.colors.to})`,
            }}
            className="relative inline-flex items-center justify-center p-0.5 mb-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group hover:text-white dark:text-white focus:ring-4 focus:outline-none"
          >
            <span className={`relative px-5 py-2.5 transition-all ease-in duration-75 rounded-md ${
              userDocument.subjects.includes(subject.id) ? 'text-white' : 'bg-white dark:bg-gray-900 group-hover:bg-opacity-0'
            }`}
            >
              {subject.label}
            </span>
          </button>
        ))}

        <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">
          We currently only teach a select few, to request a new subject
          <a href="/contact-us" className="font-medium text-blue-600 hover:underline dark:text-blue-500"> Contact Us</a>
          .
        </p>
      </div>
    </div>
  );
};
