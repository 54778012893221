import React, { useEffect, useState } from 'react';
import EngagedLayout from '../../../layouts/EngagedLayout';
import { useAuth } from '../../../contexts/AuthContext';
import { AccountType } from './steps/AccountType';
import { UserInformation } from './steps/UserInformation';
import { Subjects } from './steps/Subjects';
import { OnboardingComplete } from './steps/OnboardingComplete';
import { UserDocument } from '../../../types/collections/User';
import FirebaseFirestoreService from '../../../services/database/strategies/FirebaseFirestoreService';
import StripePaymentService from '../../../services/payments/strategies/StripePaymentService';

const OnboardingLanding = () => {
  const TOTAL_NUMBER_OF_STEPS = 6;
  const [step, setStep] = useState(0);
  const {
    authState, logout, checkEmailVerification, sendVerificationEmail,
  } = useAuth();
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [userDocument, setUserDocument] = useState<UserDocument>({
    accountType: 'student',
    uid: authState.user?.uid || '',
    fullName: authState.user?.name || '',
    phoneNumber: '',
    email: authState.user?.email || '',
    addressFirstLine: '',
    addressSecondLine: '',
    city: '',
    postcode: '',
    gcseYear: '',
    subjects: [],
    id: '',
  });

  const [darkMode, setDarkMode] = useState(false);
  const [error, setError] = useState('');

  const [verificationEmailSent, setVerificationEmailSent] = useState(false);
  const [isCheckingVerification, setIsCheckingVerification] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState('');

  useEffect(() => {
    setUserDocument((prevUserDoc) => ({
      ...prevUserDoc,
      uid: authState.user?.uid ? authState.user?.uid : '',
    }));
    checkEmailVerification((isVerified) => {
      setIsEmailVerified(isVerified);
      if (isVerified) {
        setStep(1); // Skip email verification step if already verified
      }
    });
  }, [authState, checkEmailVerification]);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  const handleSendVerificationEmail = () => {
    sendVerificationEmail(
      () => {
        setVerificationEmailSent(true);
        setVerificationMessage('Verification email sent. Please check your inbox and verify your email before continuing.');
      },
      (verificationError) => {
        console.error('Error sending verification email:', verificationError);
        setError('Failed to send verification email. Please try again or contact support at dev@tutr.ai');
      },
    );
  };

  const handleCheckVerification = () => {
    setIsCheckingVerification(true);
    checkEmailVerification(
      (isVerified) => {
        setIsEmailVerified(isVerified);
        if (isVerified) {
          setStep(1);
          setVerificationMessage('Email verified successfully. You can now proceed with the onboarding process.');
        } else {
          setVerificationMessage('Your email is not verified yet. Please check your inbox and click the verification link.');
        }
        setIsCheckingVerification(false);
      },
      (verificationError) => {
        console.error('Error checking email verification:', verificationError);
        setError('Failed to check email verification status. Please try again later.');
        setIsCheckingVerification(false);
      },
    );
  };

  const createStripeCustomer = async (user: UserDocument) => {
    try {
      await StripePaymentService.createCustomer(
        {
          metadata: { uid: user.uid },
          name: user.fullName,
          email: user.email,
          phone: user.phoneNumber,
          address: {
            line1: user.addressFirstLine,
            line2: user.addressSecondLine,
            city: user.city,
            postal_code: user.postcode,
            country: 'GB',
          },
        },
        async (customerId) => {
          console.log('Stripe customer created successfully:', customerId);
          // Update the user's document in Firestore with the new stripeCustomerId
          try {
            await FirebaseFirestoreService.updateDocument(
              'users',
              user.uid,
              { stripeCustomerId: customerId },
              () => {
                console.log('User document updated with Stripe customer ID');
                setStep(Math.min(step + 1, TOTAL_NUMBER_OF_STEPS - 1));
              },
              (updateError) => {
                console.error('Error updating user document:', updateError);
                // Handle error (you might want to show an error message to the user)
              },
            );
          } catch (updateError) {
            console.error('Error updating user document:', updateError);
            // Handle error (you might want to show an error message to the user)
          }
        },
        (createError) => {
          console.error('Error creating Stripe customer:', createError.message);
          // Handle error (you might want to show an error message to the user)
        },
      );
    } catch (stripeError) {
      console.error('Error in createStripeCustomer:', stripeError);
      // Handle error (you might want to show an error message to the user)
    }
  };

  const validateForm = () => {
    let valid = true;

    if (userDocument.fullName === '') {
      valid = false;
    }

    const pattern = /^\+?[0-9]{2}\s?0?[0-9]{5}\s?[0-9]{5}$/;
    if (!pattern.test(userDocument.phoneNumber)) {
      valid = false;
    }

    const emailPattern = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/;
    if (!emailPattern.test(userDocument.email)) {
      valid = false;
    }

    if (
      !userDocument.gcseYear
        || userDocument.gcseYear === ''
      || parseInt(userDocument.gcseYear, 10) <= 1960
        || parseInt(userDocument.gcseYear, 10) >= 2060) {
      valid = false;
    }

    if (userDocument.accountType === 'tutor' && (!userDocument.addressFirstLine || userDocument.addressFirstLine === '')) {
      valid = false;
    }

    if (userDocument.accountType === 'tutor' && (userDocument.city === '' || !userDocument.city)) {
      valid = false;
    }

    if (userDocument.accountType === 'tutor' && (!userDocument.postcode || userDocument.postcode === '')) {
      valid = false;
    }

    return valid;
  };

  return (
    <EngagedLayout>
      <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800 absolute top-0 w-full">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <a href="/" className="flex items-center">
            <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Tütr.ai</span>
          </a>
          <div className="flex items-center lg:order-3">

            {authState.isAuthenticated && (
              <button
                type="button"
                className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800"
                onClick={() => { logout(() => { window.location.href = '/'; }, () => { window.location.href = '/'; }); }}
              >
                Signout
              </button>
            )}

            <button
              data-tooltip-target="toggle-colors-example-toggle-dark-mode-tooltip"
              type="button"
              data-toggle-dark="dark"
              className="flex items-center w-9 h-9 justify-center text-xs font-medium text-gray-700 bg-white border border-gray-200 rounded-lg toggle-dark-state-example hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-500 dark:bg-gray-800 focus:outline-none dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              onClick={() => { setDarkMode(!darkMode); }}
            >
              <svg
                data-toggle-icon="moon"
                className={`w-3.5 h-3.5 ${!darkMode ? '' : 'hidden'}`}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 20"
              >
                <path d="M17.8 13.75a1 1 0 0 0-.859-.5A7.488 7.488 0 0 1 10.52 2a1 1 0 0 0 0-.969A1.035 1.035 0 0 0 9.687.5h-.113a9.5 9.5 0 1 0 8.222 14.247 1 1 0 0 0 .004-.997Z" />
              </svg>
              <svg data-toggle-icon="sun" className={`w-3.5 h-3.5 ${darkMode ? '' : 'hidden'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 15a5 5 0 1 0 0-10 5 5 0 0 0 0 10Zm0-11a1 1 0 0 0 1-1V1a1 1 0 0 0-2 0v2a1 1 0 0 0 1 1Zm0 12a1 1 0 0 0-1 1v2a1 1 0 1 0 2 0v-2a1 1 0 0 0-1-1ZM4.343 5.757a1 1 0 0 0 1.414-1.414L4.343 2.929a1 1 0 0 0-1.414 1.414l1.414 1.414Zm11.314 8.486a1 1 0 0 0-1.414 1.414l1.414 1.414a1 1 0 0 0 1.414-1.414l-1.414-1.414ZM4 10a1 1 0 0 0-1-1H1a1 1 0 0 0 0 2h2a1 1 0 0 0 1-1Zm15-1h-2a1 1 0 1 0 0 2h2a1 1 0 0 0 0-2ZM4.343 14.243l-1.414 1.414a1 1 0 1 0 1.414 1.414l1.414-1.414a1 1 0 0 0-1.414-1.414ZM14.95 6.05a1 1 0 0 0 .707-.293l1.414-1.414a1 1 0 1 0-1.414-1.414l-1.414 1.414a1 1 0 0 0 .707 1.707Z" />
              </svg>
              <span className="sr-only">Toggle dark/light mode</span>
            </button>
          </div>
        </div>
      </nav>

      <section className=" py-[100px] bg-white dark:bg-gray-900 w-full min-h-[80vh] ">
        <div className="flex gap-2 items-center w-[80%] m-auto">
          <div style={{ width: `${Math.round(((step + 1) / TOTAL_NUMBER_OF_STEPS) * 100)}%` }} className="h-2 bg-primary rounded dark:bg-white" />
          <div style={{ width: `${Math.round(((TOTAL_NUMBER_OF_STEPS - step - 1) / TOTAL_NUMBER_OF_STEPS) * 100)}%` }} className="h-2 bg-gray-300 rounded dark:bg-gray-500" />
        </div>

        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6 min-h-[80vh] flex items-center">

          {step === 0 && (
            <div className="mx-auto max-w-screen-sm text-center">
              <h2 className="mb-4 text-4xl font-extrabold leading-tight text-gray-900 dark:text-white">Verify Your Email</h2>
              <p className="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">
                {isEmailVerified
                  ? 'Your email has been verified. You can proceed with the onboarding process.'
                  : `Please verify your email address (${authState.user?.email || 'Email Not Found...'}) to continue with the onboarding process.`}
              </p>
              {!isEmailVerified && (
                <div className="flex flex-col items-center space-y-4">
                  {!verificationEmailSent ? (
                    <button
                      type="button"
                      onClick={handleSendVerificationEmail}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    >
                      Send Verification Email
                    </button>
                  ) : (
                    <>
                      <p className="text-green-600 dark:text-green-400">{verificationMessage}</p>
                      <button
                        type="button"
                        onClick={handleCheckVerification}
                        disabled={isCheckingVerification}
                        className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800 disabled:bg-green-500 dark:disabled:bg-green-800"
                      >
                        {isCheckingVerification ? 'Checking...' : 'Check Verification Status'}
                      </button>
                    </>
                  )}
                  {error && <p className="text-rose-700">{error}</p>}
                </div>
              )}
            </div>
          )}

          {step === 1 && (
            <div className="mx-auto max-w-screen-sm text-center">
              <h2 className="mb-4 text-4xl font-extrabold leading-tight text-gray-900 dark:text-white">Welcome to Tütr.ai</h2>
              <p className="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">Walk through our quick onboarding to create your account!</p>
            </div>
          )}

          {step === 2 && (
            <AccountType
              setUserDocument={setUserDocument}
              userDocument={userDocument}
              nextStep={() => {
                setStep(3);
              }}
            />
          )}

          {step === 3 && (
            <UserInformation
              setUserDocument={setUserDocument}
              userDocument={userDocument}
            />
          )}

          {step === 4 && (
            <Subjects
              setUserDocument={setUserDocument}
              userDocument={userDocument}
            />
          )}

          {step === 5 && <OnboardingComplete userDocument={userDocument} />}

        </div>

        <div className="flex justify-between items-center w-[80%] m-auto">
          <button
            type="button"
            onClick={() => {
              setStep(Math.max(step - 1, 0));
            }}
            disabled={step === 0 || step === TOTAL_NUMBER_OF_STEPS - 1}
            className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800 disabled:bg-gray-500 dark:disabled:bg-gray-800"
          >
            Previous Step
          </button>
          {step < TOTAL_NUMBER_OF_STEPS - 2 && step !== 0 && (
            <button
              type="button"
              onClick={() => {
                setStep(Math.min(step + 1, TOTAL_NUMBER_OF_STEPS - 1));
              }}
              disabled={(step === 0 && !isEmailVerified) || (step === 3 && !validateForm())}
              className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800 disabled:bg-gray-500 dark:disabled:bg-gray-800"
            >
              Next Step
            </button>
          )}
          {step === 4 && (
            <button
              type="button"
              onClick={() => {
                if (authState.user) {
                  FirebaseFirestoreService.updateDocument(
                    'users',
                    authState.user.uid,
                    userDocument,
                    () => {
                      createStripeCustomer({ ...userDocument, id: authState.user!.uid });
                      setStep(Math.min(step + 1, TOTAL_NUMBER_OF_STEPS - 1));
                    },
                    () => {
                      // console.log('Failed to get anywhere');
                    },
                  );
                }
              }}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 disabled:bg-blue-500 dark:disabled:bg-blue-800"
            >
              Submit User Information
            </button>
          )}
          {step === TOTAL_NUMBER_OF_STEPS - 1 && (
            <button
              type="button"
              onClick={() => {
                window.location.href = '/';
              }}
              className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800"
            >
              Continue to Landing
            </button>
          )}
        </div>
      </section>
    </EngagedLayout>
  );
};

export default OnboardingLanding;
