import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Jdenticon from 'react-jdenticon';
import { useAuth } from '../contexts/AuthContext';
import ScrollableContentLayout from '../layouts/ScrollableContentLayout';
import { documentToUserDoc, UserDocument } from '../types/collections/User';
import FirebaseFirestoreService from '../services/database/strategies/FirebaseFirestoreService';
import { Breadcrumbs } from '../components/breadcrumbs/Breadcrumbs';
import { EditUserInformation } from '../components/page-components/settings/EditUserInformation';
import { EditTutorProfile } from '../components/page-components/settings/EditTutorProfile';

export interface SettingsProps {}

type UsersPages = 'Profile' | 'Tutor Card' | 'Invoices';

export const Settings: React.FC<SettingsProps> = () => {
  const { authState } = useAuth();
  const [userDoc, setUserDoc] = useState<UserDocument>();
  const navigate = useNavigate();
  const location = useLocation();

  const getPageFromSearchParams = (): UsersPages => {
    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get('page');
    if (page === 'Profile' || page === 'Tutor Card' || page === 'Invoices') {
      return page;
    }
    return 'Profile'; // Default to 'Profile' if no valid page is set
  };

  const [pageOpened, setPageOpened] = useState<UsersPages>(getPageFromSearchParams());

  useEffect(() => {
    if (authState.isAuthenticated !== undefined && !authState.isAuthenticated) {
      navigate('/authenticate');
    } else if (authState.user) {
      FirebaseFirestoreService.queryDocuments(
        'users',
        'uid',
        authState.user?.uid as string,
        'uid',
        1,
        undefined,
        (doc) => {
          if (doc.length > 0) {
            setUserDoc(documentToUserDoc(doc[0]));
          } else {
            navigate('/authenticate');
          }
        },
        () => {
          navigate('/authenticate');
        },
      );
    }
  }, [authState.user, authState.isAuthenticated, navigate]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', pageOpened);
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  }, [pageOpened, navigate, location.pathname, location.search]);

  const handlePageChange = (page: UsersPages) => {
    setPageOpened(page);
  };

  return (
    <ScrollableContentLayout>
      {userDoc && (
        <section className="bg-gray-50 dark:bg-gray-800 border-t border-gray-100">
          <div className="gap-5 items-start py-8 px-4 mx-auto max-w-screen-xl flex flex-col w-full">
            <Breadcrumbs hrefs={['/']} pathNames={['Home', userDoc.fullName]} />
            <div className="flex gap-4 sm:gap-16 flex-col sm:flex-row">
              <Jdenticon size="100" value={userDoc.fullName} />
              <div>
                <h2 className="mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">{userDoc.fullName}</h2>
                <div className="inline-flex rounded-md shadow-sm" role="group">
                  <button
                    type="button"
                    onClick={() => handlePageChange('Profile')}
                    disabled={pageOpened === 'Profile'}
                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white disabled:bg-blue-500 disabled:dark:bg-blue-500"
                  >
                    <svg
                      className="w-3 h-3 me-2"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"
                      />
                    </svg>
                    Profile
                  </button>
                  {userDoc.accountType === 'tutor' && (
                    <button
                      type="button"
                      onClick={() => handlePageChange('Tutor Card')}
                      disabled={pageOpened === 'Tutor Card'}
                      className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white disabled:bg-blue-500 disabled:dark:bg-blue-500 gap-2"
                    >
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4 4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H4Zm10 5a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1Zm-8-5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm1.942 4a3 3 0 0 0-2.847 2.051l-.044.133-.004.012c-.042.126-.055.167-.042.195.006.013.02.023.038.039.032.025.08.064.146.155A1 1 0 0 0 6 17h6a1 1 0 0 0 .811-.415.713.713 0 0 1 .146-.155c.019-.016.031-.026.038-.04.014-.027 0-.068-.042-.194l-.004-.012-.044-.133A3 3 0 0 0 10.059 14H7.942Z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Tutor Card
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={() => handlePageChange('Invoices')}
                    disabled={pageOpened === 'Invoices'}
                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white disabled:bg-blue-500 disabled:dark:bg-blue-500 gap-2"
                  >
                    <svg
                      className="w-3 h-3 me-2"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z"
                      />
                      <path
                        d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
                      />
                    </svg>
                    Invoices
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="text:black dark:text-white h-full">
        <div className="py-8 px-4 mx-auto max-w-screen-xl w-full">
          {pageOpened === 'Profile' && userDoc && <EditUserInformation userDoc={userDoc} />}
          {pageOpened === 'Tutor Card' && userDoc && <EditTutorProfile userDoc={userDoc} />}
          {pageOpened === 'Invoices' && <div>Invoices content goes here</div>}
        </div>
      </section>
    </ScrollableContentLayout>
  );
};
