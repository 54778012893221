import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FirebaseFirestoreService from '../services/database/strategies/FirebaseFirestoreService';

const PaymentSuccessHandler: React.FC = () => {
  const [searchParams] = useSearchParams();

  const [messageId] = useState(searchParams.get('message_id'));
  console.log(messageId);
  const navigate = useNavigate();

  useEffect(() => {
    const updatePaymentStatus = async () => {
      console.log(messageId);
      if (messageId) {
        FirebaseFirestoreService.updateDocument(
          'messages',
          messageId,
          {
            status: 'paid',
          },
          () => {
            navigate(`/create-lesson?message_id=${messageId}`);
          },
          (error) => {
            console.error('Failed to update payment status:', error);
          },
        );
      }
    };

    updatePaymentStatus();
  }, [messageId, navigate]);

  return (
    <div className="text-center bg-sky-500 dark:bg-sky-900 flex items-center justify-center w-screen h-screen flex-col gap-2">
      <div className="flex justify-center items-center rounded-full border border-white">
        <div className="animate-spin rounded-full h-12 w-12 border-b-4 border-blue-700" />
      </div>
      <h2 className="text-2xl text-gray-900 dark:text-white font-bold">Processing your payment...</h2>
    </div>
  );
};

export default PaymentSuccessHandler;
