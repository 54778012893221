import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../../contexts/AuthContext';
import {
  emptyTutorDocument, TutorDocument, TutorOnboardingState, getTutorOnboardingState,
} from '../../../../types/collections/Tutor';
import { UserDocument } from '../../../../types/collections/User';
import FirebaseFirestoreService from '../../../../services/database/strategies/FirebaseFirestoreService';
import { BreadcrumbStepper } from '../../../stepper/BreadcrumbStepper';
import { TutorInformation } from './TutorInformation';

export interface TutorOnboardingProps {
  userDoc: UserDocument;
}

const onboardingStateToStep: { [key in TutorOnboardingState]: number } = {
  'Account Created': 0,
  'Employment Information Complete': 1,
  'Account Reviewed': 2,
  'DBS Check Confirmed': 3,
  'Document Completed': 4,
};

export const TutorOnboarding: React.FC<TutorOnboardingProps> = ({ userDoc }) => {
  // Tutoring Information
  const [tutorDocument, setTutorDocument] = useState<TutorDocument>(emptyTutorDocument);
  const [hiddenComponent, setHidden] = useState(false);
  const { authState } = useAuth();
  const [userId, setUserId] = useState('');
  const [onboardingStep, setOnboardingStep] = useState<TutorOnboardingState>('Account Created');

  useEffect(() => {
    if (authState.isAuthenticated && authState.user?.uid) {
      setUserId(authState.user.uid);
      FirebaseFirestoreService.queryDocuments(
        'tutors',
        'uid',
        authState.user.uid,
        'uid',
        1,
        undefined,
        (doc) => {
          if (doc.length > 0) {
            FirebaseFirestoreService.listenToDocument(
              'tutors',
              doc[0].id!,
              (tutorDoc) => {
                console.log(tutorDoc);
                setTutorDocument(tutorDoc as TutorDocument);
              },
              (error) => {
                console.error(error);
              },
            );
            setTutorDocument(doc[0] as TutorDocument);
          }
        },
        (error) => {
          console.log(error);
        },

      );
    }
  }, [authState]);

  useEffect(() => {
    if (tutorDocument !== emptyTutorDocument) {
      setOnboardingStep(getTutorOnboardingState(tutorDocument));
    }
  }, [tutorDocument]);

  useEffect(() => {
    setTutorDocument((prevState) => ({
      ...prevState,
      uid: userDoc.uid,
    }));
  }, [userDoc]);

  const createOrUpdateTutorDocument = (values: Partial<TutorDocument>) => {
    if (authState.isAuthenticated && authState.user?.uid) {
      setUserId(authState.user.uid);
      FirebaseFirestoreService.queryDocuments(
        'tutors',
        'uid',
        authState.user.uid,
        'uid',
        1,
        undefined,
        (doc) => {
          if (doc.length > 0) {
            console.log(doc[0].id);
            FirebaseFirestoreService.updateDocument(
              'tutors',
              doc[0].id!,
              values,
              () => { console.log('Successfully updated the userDocument'); },
              () => { console.log('Failed to update the userDocument'); },
            );
          } else {
            FirebaseFirestoreService.addDocument(
              'tutors',
              {
                ...tutorDocument,
                ...values,
              },
              (documentId) => {
                FirebaseFirestoreService.listenToDocument(
                  'tutors',
                  documentId,
                  (tutorDoc) => {
                    console.log(tutorDoc);
                    setTutorDocument(tutorDoc as TutorDocument);
                  },
                  (error) => {
                    console.error(error);
                  },
                );
                console.log('Created a new Tutor Document');
              },
              () => {
                console.log('Failed to add a new Tutor Document');
              },
            );
          }
        },
        (error) => {
          console.log(error);
        },

      );
    } else {
      console.error('User Not Authenticated');
    }
  };

  if (!hiddenComponent) {
    return (
      <div
        className="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 relative"
      >

        <div className="absolute top-0 right-0 p-1">
          <button type="button" onClick={() => { setHidden(true); }} className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">hide</button>
        </div>

        <div className="p-4 bg-white rounded-lg dark:bg-gray-800" id="faq" role="tabpanel" aria-labelledby="faq-tab">
          <div
            id="accordion-flush"
            data-accordion="collapse"
            data-active-classes="bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
            data-inactive-classes="text-gray-500 dark:text-gray-400"
          >
            {/* Tutoring Onboarding Progress */}
            <div
              className="flex flex-col sm:flex-row flex-wrap w-full p-5 py-8 gap-5 sm:gap-10 items-center justify-center"
            >
              <BreadcrumbStepper
                stepCompletedIndex={onboardingStateToStep[onboardingStep]}
                stepNames={[
                  'Account Created',
                  'Employment Information',
                  'Account Confirmed',
                  'DBS Check Confirmed',
                ]}
              />
            </div>

            {onboardingStep === 'Account Created' && (
            <TutorInformation
              tutorDocument={tutorDocument}
              createOrUpdateTutorDocument={createOrUpdateTutorDocument}
              userId={userId}
            />
            )}

            {
            onboardingStep === 'Employment Information Complete' && (
              <div className="w-full flex flex-col gap-2 p-5">
                <p className="text-black dark:text-white text-xl font-bold">
                  Processing your application.
                </p>
                <span className="text-black dark:text-white ">
                  Our team is currently reviewing your application.
                  Please make sure your email is valid.
                  While you wait
                  <a href="/settings?page=Tutor+Card" className="text-primary underline"> customise the tutor card </a>
                  that will be shown to our students.
                </span>
              </div>
            )
          }
          </div>
        </div>
      </div>
    );
  }
  return <div><p /></div>;
};
